@use "sass:map";

> .navbar-sticky {
  background-color: $white;
}

@include media-breakpoint-up(lg) {
  > .navbar-sticky {
    background-color: $primary;
  }
}

.navbar {
  &:not(.navbar-stuck-menu) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    > .container {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
    }
  }
  &.navbar-stuck-menu {
    padding: 0rem;
  }

  @include media-breakpoint-down(lg) {
    &.navbar-mobile {
      position: fixed;
      top: 0px;
      left: -100%;
      bottom: 0px;
      z-index: 11;
      display: block;
      padding: 0px;
      width: 350px;
      max-width: calc(100% - 42px);
      background: #ffffff;
      transition: all .3s;

      .mobile-menu-close {
        position: absolute;
        top: 0px;
        right: -42px;
        height: 48px;
        width: 42px;
        font-size: 15px;
        color: $white;
        background-color: $color-dark;
        border: 0px;
      }
      .nav-link {
        font-weight: 400;
        color: $color-dark;
      }
      .inner-wrp {
        position: relative;
        overflow: auto;
        height: 100%;

        .top-menu {
          ul {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 1px;

            li {
              margin: 0px;

              a {
                display: block;
                padding: 17px 15px;
                font-weight: 600;
                font-size: 12px;
                line-height: 24px;
                text-align: center;
                text-transform: uppercase;
                background-color: $primary;
                border-bottom: 1px solid $primary;
                opacity: 0.7;
                transition: all .3s;

                &.active, &:hover {
                  border-color: $color-dark;
                  opacity: 1;
                }
              }
            }
          }
        }

        > .container {
          margin-top: 36px;
          padding: {
            left: 40px;
            right: 40px;
          };
          > .navbar-nav {
            margin-bottom: 20px;

            .dropdown {
              position: initial;

              > .nav-link {
                padding: 3px 0px 2px 0px;

                &.dropdown-toggle {
                  position: relative;
                  padding-right: 20px;

                  &:after {
                    position: absolute;
                    top: 50%;
                    right: 0px;
                    width: 10px;
                    height: 10px;
                    border: none;
                    border-right: 2px solid $color-dark;
                    border-bottom: 2px solid $color-dark;
                    transform: translateY(-50%) rotate(-45deg);
                  }
                }
              }
              .dropdown-menu {
                width: 350px;
                max-width: calc(100% - 42px);
                background-color: $white;

                .dropdown-title-mob {
                  position: relative;
                  display: block;
                  padding: 13px 40px;
                  font-weight: 400;
                  font-size: 24px;
                  line-height: 32px;
                  font-family: $font-family-the-seasons;
                  text-align: center;
                  background: $primary;

                  &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 42px;
                    width: 10px;
                    height: 10px;
                    border: none;
                    border-right: 2px solid #222222;
                    border-bottom: 2px solid #222222;
                    transform: translateY(-50%) rotate(135deg);
                  }
                  & + div {
                    padding: 40px 40px 20px 40px;
                    height: calc(100vh - 58px);
                    overflow: auto;
                  }
                }
                .column-title {
                  margin-bottom: 16px;
                  font-weight: 500;
                  font-size: 10px;
                  line-height: 24px;
                  letter-spacing: 0.1em;
                  text-transform: uppercase;
                  font-family: $font-family-base;
                  color: $color-dark;
                }
                .show-all-link {
                  display: block;
                  margin-top: 20px;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 24px;
                  text-decoration-line: underline;
                }
                &.show {
                  position: fixed;
                  top: 0px;
                  left: 0px;
                  bottom: 0px;
                  margin: 0px;
                }
              }
            }
            .widget-banner {
              display: block;
              margin-bottom: 20px;

              span {
                display: block;
                margin-top: 8px;
                font-weight: 500;
                font-size: 10px;
                line-height: 24px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
              }
            }
          }
          .mobile-menu-group {
            position: relative;
            padding: 23px 0px 0px 0px;
            margin-bottom: 21px;
            list-style: none;
            font-size: 14px;
            line-height: 16px;

            &:before {
              content: "";
              position: absolute;
              top: 0px;
              left: 0px;
              display: block;
              height: 1px;
              width: 32px;
              background-color: #000000;
            }
            li {
              a {
                padding: 10px 0px;
              }
            }
          }
        }
      }
      &.open {
        left: 0px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &:not(.navbar-stuck-menu) {
      padding-top: 0.9rem;
      padding-bottom: 0px;
    }
    &.navbar-stuck-menu {
      display: block;
    }
    &.navbar-expand-lg {
      .navbar-nav {
        display: grid;
        grid-auto-flow: column;
        column-gap: 30px;

        .nav-link {
          padding: {
            top: 0.85rem;
            bottom: 1.775rem;
            left: 0rem;
            right: 0rem;
          }
          font-size: 14px;
          line-height: 24px;
          color: $color-dark;
        }
      }
    }
  }
}
.navbar-toggler {
  padding: 0.45rem 0.1rem;
}
.navbar-tool-icon-box {

  @include media-breakpoint-up(lg) {
    .navbar-tool-icon {
      font-size: 0.85rem;
    }
  }
}

.top-menu {
  ul {
    display: flex;
    list-style: none;
    padding-left: 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;

    li {
      a {

      }
      &:not(:last-child) {
        margin-right: 32px;
      }
    }
  }
}


#cartInfo {
  .navbar-tool-label {
    top: 0.45rem;
    right: 0.45rem;
    width: 0.6rem;
    height: 0.6rem;
    background-color: $success;
    border: 1px solid $primary;
  }

  &:hover {
    .dropdown-toggle {
      &:after {
        content: "";
        position: absolute;
        bottom: -5px;
        right: 9px;
        display: block;
        width: 16px;
        height: 1px;
        background-color: $color-dark;
        border: none;
      }
    }
  }
  .dropdown-menu {
    right: 8px;
    padding: 26px 0px 0px 0px;
    width: 467px;
    border-radius: 0px;

    .cart-title {
      margin-bottom: 22px;
      padding: 0px 35px;
    }
    .items-wrp {
      margin: 0px 35px 0px 35px;
    }
    .widget-cart-item {
      margin-bottom: 12px;

      .widget-cart-item-content {
        display: grid;
        grid-template-columns: 88px auto;
        column-gap: 20px;
        align-items: center;

        .widget-cart-item-texts {
          display: flex;
          flex-direction: column;
          padding: 9px 0px 5px 0px;

          .brand-title {
            font-weight: 500;
            font-size: 10px;
            line-height: 24px;
            text-transform: uppercase;
            letter-spacing: 0.1em;
          }
          .product-title {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            font-family: $font-family-basier-squere;
          }
          .spacer {
            flex-grow: 1;
          }
          .modification-title {
            font-size: 14px;
            line-height: 22px;
            color: #8D8D8D;
          }
          .price-wrp {
            span {
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              color: $color-dark;
            }
          }
        }
      }
    }
    .btn-wrp {
      position: relative;
      padding: 25px 35px 26px 35px;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
    .empty-cart-wrp {
      margin: 0px 35px 35px 35px;
    }
  }
}