#subscribers_subscribe {
  padding-top: 48px;
  padding-bottom: 56px;
  text-align: center;
  background-color: $white;

  > .container {
    max-width: 477px;
  }
  h2 {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-family: $font-family-base;
  }
  h3 {
    margin-bottom: 6px;
    font-weight: 400;
    font-size: 28px;
    line-height: 37px;
  }
  p {
    margin-bottom: 29px;
    font-size: 14px;
    line-height: 16px;
  }
  #newsletter_form {
    .input-group-wrp {
      display: grid;
      grid-auto-flow: row;
      row-gap: 12px;
    }
  }

  @include media-breakpoint-up(lg) {
    p {
      margin-bottom: 45px;
    }
    #newsletter_form {
      .input-group-wrp {
        grid-auto-flow: column;
        grid-template-columns: 1fr auto;
        column-gap: 12px;

        button {
          min-width: 144px;
        }
      }
    }
  }
}