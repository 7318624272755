#instaPosts {
  text-align: center;

  h2 {}
  p {
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  .slider-wrp {
    position: relative;
    margin: {
      top: 32px;
      left: -20px;
      right: -20px;
    }
    padding: {
      left: 20px;
      right: 20px;
    }
    overflow: hidden;

    .tns-carousel {
      .tns-ovh {
        overflow: initial;
      }
    }
    .post-element {
      display: block;
    }
    .tns-carousel-controls {
      button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &[data-controls="prev"] {
          left: 8px;
        }
        &[data-controls="next"] {
          right: 8px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .slider-wrp {

    }
  }
}