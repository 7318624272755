@import 'components/buttons';
@include make-grid-columns-project();
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: $color-dark;
  font-family: $font-family-heading;
}
h2 {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 8px;
    font-size: 40px;
    line-height: 48px;
  }
}
.cursor-not-allowed {
  cursor: not-allowed;
}

.object-fit-cover {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 0;

  img {
    max-width: initial;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.object-fit-contain{
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 0;

  img {
    max-width: initial;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}


.object-fit-video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.form-label {
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.form-control {
  padding: 0.75rem 1rem;
  font-size: 14px;
  line-height: 16px;
  border-color: $color-dark;
  border-radius: 0;

  &:focus {
    border-color: $color-dark;
  }
}
.form-check-input {
  width: 20px;
  height: 20px;
  border-color: $color-dark;

  &[type=checkbox] {
    border-radius: 0px;
  }
  &:checked {
    background-color: $color-dark;
    border-color: $color-dark;
  }
  &:focus {
    border-color: $color-dark;
  }
}
.form-check {
  padding-left: 28px;

  .form-check-input {
    margin-left: -28px;
  }
  a {
    text-decoration: underline;
  }
}
.form-check-label {
  color: $color-dark;
}
.list-collapse-mobile {
  @include media-breakpoint-down(md) {
    border-bottom: 1px solid $color-dark;

    .title {
      position: relative;
      margin: 0px;
      padding: {
        top: 20px;
        bottom: 20px;
        right: 25px;
      };
      font-size: 20px;
      line-height: 26px;
      cursor: pointer;

      &:after {
        content: "\e906";
        position: absolute;
        right: 0px;
        top: 50%;
        display: block;
        width: 15px;
        height: 15px;
        font-size: 14px;
        font-weight: bold;
        line-height: 15px;
        font-family: "cartzilla-icons" !important;
        transform: translateY(-50%);
      }
    }
    > ul {
      display: none;
    }
    &.open {
      .title {
        &:after {
          content: "\e90c";
        }
      }
      > ul {
        display: block;
      }
    }
  }
}

.heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  padding-top: 34px;

  .type {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  .title {
    font-size: 32px;
    line-height: 40px;
  }
  .top-description {
    margin-top: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
  &.wt-photo {
    .type {
      grid-area: type;
    }
    .title {
      grid-area: title;
    }
    .top-description {
      grid-area: description;
    }
    .banner {
      grid-area: banner;
      margin-top: 16px;
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 44px;
    padding-top: 25px;

    .type {
      margin-bottom: 7px;
    }
    .title {
      font-size: 40px;
      line-height: 40px;
    }
    &.wt-photo {
      display: grid;
      grid-template-columns: 436px 54.7%;
      grid-template-areas: "spacer banner"
                         "type banner"
                         "title banner"
                         "spacer2 banner";
      justify-content: space-between;
      column-gap: 40px;
      padding: 32px 0px;

      .top-description {
        margin-top: 24px;
        text-align: left;
      }
      .banner {
        position: relative;
        overflow: hidden;
        margin: -32px 0px;
        min-height: 330px;
        height: calc(100% + 64px);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &.wt-description {
        grid-template-areas: "spacer banner"
                         "type banner"
                         "title banner"
                         "description banner"
                         "spacer2 banner";
      }
    }
  }
}

.sidebar-wrp {
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  z-index: 12;
  padding: 24px 24px 0px 24px;
  max-width: 360px;
  width: 100%;
  background-color: #fff;
  transition: all .6s;

  .close-sidebar {
    position: absolute !important;
    top: 12px;
    right: 10px;
    z-index: 1;

    i {
      color: $color-dark;
    }
  }
  &.open {
    right: 0;
  }
  .side-content-wrp {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    .page-title {
      margin-top: 0px;
      margin-bottom: 0px;
      padding-bottom: 26px;
      font-weight: 400;
      font-size: 32px;
      line-height: 48px;
      border-bottom: 1px solid $color-dark;
    }
    .content {
      flex-grow: 1;
      overflow-y: auto;
    }
    .actions-wrp {
      flex-shrink: 0;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: minmax(0, 1fr);
      column-gap: 8px;
      margin: {
        left: -24px;
        right: -24px;
      };
      padding: 24px;
      box-shadow: 1px -6px 1.525rem -0.375rem rgba(0, 0, 0, 0.1) !important;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 24px 24px 0px 24px;

    .side-content-wrp {
      .page-title {
        padding-bottom: 32px;
        font-size: 28px;
        line-height: 36px;
      }
    }
  }
}

.add_to_wishlist2 {
  .ci-heart-filled {
    display: none;
  }

  &.active {
    .ci-heart {
      display: none;
    }
    .ci-heart-filled {
      display: block;
    }
  }
}

.widget-list-link {
  color: $color-dark;
}
.widget-categories {
  .accordion-button {
    margin: 4px 0px;
    padding: 4px 0px;
    font-size: 14px;
    line-height: 24px;
    color: $color-dark;
    font-family: $font-family-base;

    &:hover {
      color: $color-dark;
    }
    &.collapsed {
      color: $color-dark;
    }
  }
  .accordion-header {
    &.no-childs {
      .accordion-button:after {
        display: none;
      }
    }
  }
  .widget-list-item {
    padding: {
      top: 4px;
      bottom: 4px;
    }
  }
}

.tags {
  .badge {
    margin-bottom: 4px;
    font-size: 0.6rem;
    background-color: var(--badge-color);
    box-shadow: 0 0.5rem 1.125rem -0.275rem var(--badge-color);

    img {
      max-width: 30px;
    }
  }

  &.tags-tt, &.tags-bb {
    left: 0px;
    right: 0px;

    .special_tag {
      padding: 0px !important;
    }
  }
  &.tags-tt {
    top: 0px;
  }
  &.tags-bb {
    bottom: 0px;
  }
  &.tags-tl {
    top: 0.75rem;
    left: 0.75rem;

    .tag {
      float: left;
    }
  }
  &.tags-bl {
    top: initial;
    bottom: 0.75rem;
    left: 0.75rem;

    .tag {
      float: left;
    }
  }
  &.tags-tr {
    top: 15px;
    right: 0px;
    width: 50%;
  }
  &.tags-br {
    bottom: 15px;
    right: 0px;
  }
  &.tags-tl, &.tags-tr {
    &.tt-gap {
      top: 35%;
    }
  }
  &.tags-bl, &.tags-br {
    .tag {
      margin-top: 10px;
      margin-bottom: 0px;
    }
    &.tt-gap {
      bottom: 35%;
    }
  }

  @include media-breakpoint-up(lg) {
    .badge {
      font-size: 0.75rem;
    }
  }
}

#ajax_loader {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);

  .load-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 66px;
    height: 20px;
    animation: c 1.8s linear infinite;
    -webkit-animation: c 1.8s linear infinite;

    &:before, &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      border-radius: 20px;
      background: hsla(0, 0%, 100%, 0.9);
    }
    &:before {
      left: 0;
      animation: d 1.8s linear infinite;
      -webkit-animation: d 1.8s linear infinite;
    }
    &:after {
      right: 0;
      animation: e 1.8s linear infinite;
      -webkit-animation: e 1.8s linear infinite;
      animation-delay: -0.9s;
      -webkit-animation-delay: -0.9s;
    }
  }
}

#searchCarousel {
  @include media-breakpoint-up(md) {
    .product-card {
      max-width: 300px;
    }
  }
}

.overlay, .overlay_temp {
  position: fixed;
  top:0;
  right:0;
  left:0;
  bottom:0;
  display: none;
  background-color: black;
  opacity: 0.5;
  z-index: 3;
}
.overlay_temp {
  display: block;
  z-index: 10;
}

.link-icon-box {
  display: grid;
  grid-template-columns: 14px auto;
  column-gap: 17px;

  .link-icon {}
}

.tns-carousel {
  &.tns-nav-scrollbar {
    .tns-nav {
      position: relative;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      padding: 0px;
      height: 3px;
      width: 93px;
      background-color: #D9D9D9;
      @include tiny_slider_scrollbar('tiny_slider_nav', 20);

      &:after {
        content: "";
        position: absolute;
        top: 0px;
        display: block;
        height: 3px;
        background-color: $color-dark;
        transition: all .3s;
      }
      [data-nav] {
        display: none;
      }
    }
  }
}
.tns-carousel-controls, #recentlyViewedProducts .tns-carousel {
  [data-controls] {
    width: 40px !important;
    height: 40px !important;
    color: $white;
    background-color: $color-dark !important;
    border: $color-dark !important;
    border-radius: 0px !important;
  }
}

.accordion {
  .accordion-item {
    border: none;
    border-bottom: 1px solid $color-dark;
    border-radius: 0 !important;

    .accordion-header {
      .accordion-button {
        justify-content: space-between;
        padding: 15px 0px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        font-family: $font-family-base;

        &:after {
          margin-left: 15px;
          width: 8px;
          background-size: 14px;
          background-color: transparent;
        }
        &:not(.collapsed) {
          box-shadow: inset 0 -1px 0 $color-dark;
        }
      }
    }
    .accordion-body {
      padding: 20px 0px;

      .page_editor_template {
        margin: 0px;
        padding: 0px;
      }
    }
  }
}

.flatpickr-calendar {
  padding: 0px;
  max-width: 410px;
  width: 100%;
  box-shadow: none;


  .flatpickr-current-month .flatpickr-monthDropdown-months {
    line-height:1.2;
  }

  .flatpickr-months {
    .flatpickr-prev-month,
    .flatpickr-next-month {
      &:hover svg { fill: $gray-600; }
    }
  }

  .flatpickr-innerContainer {
    padding: 0px;
    overflow: visible;

    .flatpickr-rContainer {
      .flatpickr-weekdays {
        .flatpickr-weekdaycontainer {
          .flatpickr-weekday {
            font-weight: 400;
            font-size: 10px;
            line-height: 10px;
            color: $color-dark;
            font-family: $font-family-basier-squere;
          }
        }
      }
      .flatpickr-days {
        width: 100%;
        overflow: visible;

        .dayContainer {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          justify-content: initial;
          
          .flatpickr-day {
            margin: 0px !important;
            max-width: 60px;
            height: 40px;
            line-height: 40px;
            border: none !important;
            border-radius: 0px;
            box-shadow: 1px 0 0 0 #E4E4E4,
                        0 1px 0 0 #E4E4E4,
                        1px 1px 0 0 #E4E4E4,   /* Just to fix the corner */
                        1px 0 0 0 #E4E4E4 inset,
                        0 1px 0 0 #E4E4E4 inset;

            &.flatpickr-disabled {
              &.flatpickr-weekend:not(.flatpickr-soldout):not(.flatpickr-past):not(.selected):not(.inRange):not(.flatpickr-currentSelect) {
                color: #393939 !important;
              }
              &:not(.flatpickr-weekend:not(.flatpickr-soldout):not(.flatpickr-past)) {
                color: rgba(34, 34, 34, 0.3) !important;
                background-color: #FCF5F1;
              }

            }
            &.flatpickr-currentSelect {
              background-color: rgba(32, 49, 66, 0.5);
              color: #fff;
            }
            &.today:not(.flatpickr-disabled) {
              color: #393939 !important;
            }
            &.nextMonthDay:not(.selected):not(.inRange):not(.flatpickr-currentSelect),&.prevMonthDay:not(.selected):not(.inRange):not(.flatpickr-currentSelect) {
              opacity: 0;
            }
          }
        }
      }
    }
  }
  &.rangeMode {
    .flatpickr-innerContainer {
      .flatpickr-rContainer {
        .flatpickr-days {
          .dayContainer {
            .flatpickr-day {
              &.startRange, &.inRange, &.endRange {
                color: $white !important;
                background-color: $color-dark !important;
                box-shadow: none;
              }
              &.startRange {
                &:before {
                  content: "";
                  position: absolute;
                  left: 2px;
                  top: 2px;
                  display: block;
                  width: 6px;
                  height: 6px;
                  background-color: $white;
                }
              }
              &.endRange {
                &:after {
                  content: "";
                  position: absolute;
                  right: 2px;
                  bottom: 2px;
                  display: block;
                  width: 6px;
                  height: 6px;
                  background-color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
  &.inline {
    top: 0px;
  }

  @include media-breakpoint-up(md) {
    max-width: 354px;
  }
}

.date-duration-modal {
  .modal-dialog {
    max-width: 452px;

    .modal-content {
      border-radius: 0px;

      .modal-body {
        padding: 30px 20px 36px 20px;

        .btn-close {
          position: absolute;
          right: 16px;
          top: 16px;
          z-index: 1;
          color: $color-dark;
        }
        .date-picker-info-wrp {
          margin-bottom: 24px;
          padding: 12px;
          background-color: #FCF5F1;
          box-shadow: 0px 0px 0px 1px #e4e4e4 inset;

          p {
            margin: 0px;
            font-size: 12px;
            line-height: 24px;
            color: $color-dark;
          }
        }
        .date-picker-legend-wrp {
          display: grid;
          grid-template-columns: 40% auto;
          row-gap: 8px;
          column-gap: 20px;
          margin-bottom: 24px;

          .date-legend {
            display: flex;
            align-items: center;

            .indicator {
              position: relative;
              width: 22px;
              height: 22px;
              border: 1px solid #E4E4E4;

              &.start {
                background-color: $color-dark;

                &:before {
                  content: "";
                  position: absolute;
                  top: 3px;
                  left: 3px;
                  display: block;
                  width: 4px;
                  height: 4px;
                  background-color: $white;
                }
              }
              &.end {
                background-color: $color-dark;

                &:before {
                  content: "";
                  position: absolute;
                  bottom: 3px;
                  right: 3px;
                  display: block;
                  width: 4px;
                  height: 4px;
                  background-color: $white;
                }
              }
              &.disabled {
                background-color: #FCF5F1;
              }
            }
            span {
              padding-left: 12px;
              font-size: 10px;
              line-height: 16px;
              color: $color-dark;
            }
          }
        }
        .tryon-info {
          font-size: 12px;
          line-height: 24px;

          i {
            font-size: 15px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding: 20px 48px 36px 48px;
        }
      }
    }
  }
}

.go-back-link-wrp {
  margin-bottom: 10px;

  a {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    i {
      font-weight: 700;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 14px;
  }
}

#type_customers .flatpickr-calendar{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.pages-help-block {
  margin-left: auto;
  margin-right: auto;
  padding-top: 48px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;

  > .container {
    max-width: 800px;
  }
  h3 {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-family: $font-family-base;
  }
  h2 {
    margin-bottom: 60px;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
  }
  .items-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
    row-gap: 32px;
    padding-bottom: 48px;

    .item {
      .title {
        display: flex;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 0px;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-family: $font-family-base;
      }
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 22px;
    padding-top: 9px;

    h3 {}
    h2 {
      margin-bottom: 46px;
      font-size: 40px;
      line-height: 48px;
    }
    .items-list {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      column-gap: 30px;
      padding-bottom: 70px;
    }
  }
}
.delivery_time{
  padding-left: 10px;
  padding-top: 10px;
  font-size: 12px;
}

.wishlist-head{
  background-color: #fff;
  margin: 0px 0px 8px 0px;
  padding: 32px 40px;
  text-align: left;
  h1 {
    font-size: 40px;
    line-height: 48px;
  }
  @include media-breakpoint-down(md) {
    margin: 0px -20px 8px -20px;
    padding: 24px 20px 28px 20px;
    h1 {
      font-size: 24px;
      line-height: 32px;
    }
  }

}

.dream_room_message{
  color: #e70861;
}