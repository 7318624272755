@mixin make-grid-col-auto() {
  grid-template-columns: auto;
}
@mixin grid-cols($count) {
  grid-template-columns: repeat($count, minmax(0, 1fr));
}

@mixin make-grid-columns-project($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      @if $grid-row-columns > 0 {
        @for $i from 1 through $grid-row-columns {
          .grid-cols#{$infix}-#{$i} {
            @include grid-cols($i);
          }
        }
      }
    }
  }
}

@mixin tiny_slider_scrollbar($name, $item_count) {
  @for $i from 0 through $item_count {
    @for $i2 from 1 through $item_count {
      &[data-total="#{$i2}"][data-current="#{$i}"] {
        &:after {
            left: ((100% / $i2) * $i) - (100% / $i2);
            width: 100% / $i2;
        }
      }
    }
  }
}

@mixin fontello-icon($content) {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  //speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  line-height: inherit;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

  content: $content;
}

@mixin nice-scroll-params() {
  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #F0F1F3;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #F0F1F3;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #000000;
  }
}