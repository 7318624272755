@use "sass:map";

.navbar-expand-lg {
  .navbar-collapse {
    position: relative;

    .navbar-nav {
      .nav-item {
        &.fullwidth {
          position: static;

          > .dropdown-menu {
            left: 0;
            right: 0;
          }
          .mega-dropdown-column {
            max-width: initial;
            width: 100%;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .navbar-collapse {
      position: initial;

      .navbar-nav {
        .nav-item {
          &.fullwidth {
            > .dropdown-menu {
              .fullwidth-content {
                display: grid;
                column-gap: 51px;
                grid-template-columns: repeat(4, minmax(0, 1fr));
                padding: 40px 0px 20px 0px;

                .mega-dropdown-column {
                  &.col-width-1 {
                    grid-column: 1 / 2;
                  }
                  &.col-width-2 {
                    grid-column: 2 / 3;
                  }
                  &.col-width-3 {
                    grid-column: 3 / 4;
                  }
                  &.col-width-4 {
                    grid-column: 4 / 5;
                  }
                  .column-title {
                    margin-bottom: 16px;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    font-family: $font-family-base;
                    color: $color-dark;
                  }
                  .widget-banner {
                    display: block;

                    span {
                      display: block;
                      margin-top: 8px;
                      font-weight: 500;
                      font-size: 10px;
                      line-height: 24px;
                      letter-spacing: 0.1em;
                      text-transform: uppercase;
                    }
                  }
                  .widget-links {
                    .widget-list {}
                    .show-all-link {
                      display: block;
                      margin-top: 20px;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 24px;
                      text-decoration-line: underline;
                    }
                  }
                }
              }
            }
          }
          &.compact {
            > .dropdown-menu {
              .compactwidth-content {
                padding: 20px 0px 20px 0px;
              }
            }
          }
        }
      }
    }
  }
}