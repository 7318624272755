// FOR COLORS DO NOT OVERRIDE $theme-colors ARRAY
// OVERRIDE VARIABLES cartzilla/_variables.scss 28-36 lines

$border-radius: 0.3125rem !default;

$font-family-basier-squere: 'basier_square' !default;
$font-family-the-seasons: 'The Seasons' !default;
$font-family-heading: $font-family-the-seasons;
$font-family-sans-serif: $font-family-basier-squere;
$font-size-base: 1rem;
$line-height-base: 1.5;

$icons-font-path: '../../../public/fonts/cartzilla';

$enable-grid-classes: true !default;

$primary: #FBF9F6;
$success: #02CC53;
$link-color: #222222;
$color-dark: #222222;

$navbar-tool-icon-box-size: 2.175rem;
$navbar-tool-icon-font-size: 1.05rem;

$nav-link-hover-color: #222222;
$product-title-link-hover-color: #222222;
$dropdown-link-hover-color: #222222;
$nav-link-active-color: #222222;

// lightGallery
$lg-path-fonts: '../../fonts/lg'!default;

$carousel-dot-size:          .4125rem !default;
$carousel-dot-active-color:  $color-dark !default;

$btn-wishlist-color: $color-dark !default;