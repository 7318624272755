#brands_listing {

  .letters-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0px -2px 32px -2px;

    .letter-btn {
      flex-grow: 0;
      flex-shrink: 0;
      margin: 2px;
      width: 25px;
      height: 25px;
      font-size: 14px;
      line-height: 16px;
      background: transparent;
      border: none;
    }
  }
  .brands-section {
    .letter-brands-wrp {
      margin-bottom: 32px;

      .letter-wrp {
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        font-family: $font-family-heading;
      }
      .brands-wrp {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        column-gap: 40px;

        .brand-wrp {
          a {
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .container {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  @include media-breakpoint-up(sm) {
    .brands-section {
      .letter-brands-wrp {
        .brands-wrp {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .brands-section {
      .letter-brands-wrp {
        .brands-wrp {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .brands-section {
      .letter-brands-wrp {
        margin-bottom: 60px;

        .letter-wrp {
          margin-bottom: 20px;
          font-size: 40px;
          line-height: 48px;
        }
        .brands-wrp {
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .brands-section {
      .letter-brands-wrp {
        .brands-wrp {}
      }
    }
  }
}