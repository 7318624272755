.container {
  &.wt-menu {
    display: grid;
  }

  @include media-breakpoint-up(md) {
    &.wt-menu {
      grid-template-columns: 222px auto;
      column-gap: 40px;
    }
  }
}

#account-menu {
  ul {
    li {
      a {

      }
    }
  }
  @include media-breakpoint-down(md) {
    position: relative;

    .account-menu-mob-btn {
      padding: 11px 18px;
      width: 100%;
      text-align: left;
      font-size: 14px;
      line-height: 16px;
      background: #ffffff;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23373f50' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 1rem center;
      background-size: 16px 12px;
      border: 1px solid $color-dark;
      cursor: pointer;
    }
    ul {
      position: absolute;
      top: 100%;
      display: none;
      padding: 8px 0px;
      width: 100%;
      border: 1px solid $color-dark;
      background-color: $white;

      li {
        a {
          display: block;
          padding: 6px 18px;
          width: 100%;
        }
        &.active {
          display: none;
        }
      }
    }
    &.open {
      ul {
        display: block;
        z-index: 1;
      }
    }
  }
  @include media-breakpoint-up(md) {
    background-color: $white;

    ul {
      padding: 24px 0px;

      li {
        padding: 8px 0px;

        a {
          display: block;
          padding: 0px 36px;
          font-size: 14px;
          line-height: 20px;
        }
        &.active {
          a {
            position: relative;
            font-weight: 600;

            &:before {
              content: "";
              position: absolute;
              left: 0px;
              top: 0px;
              height: 100%;
              width: 2px;
              background-color: $color-dark;
            }
          }
        }
      }
    }
  }
}
#customers_settings {
  margin-left: -20px;
  margin-right: -20px;
  padding: 24px 20px;
  background-color: $white;

  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;
  }
  .btn-primary {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    margin-left: 0px;
    margin-right: 0px;
    padding: 40px;
    max-width: 828px;

    h2 {
      margin-bottom: 30px;
      font-size: 40px;
      line-height: 48px;
    }
    .btn-primary {
      min-width: 163px;
      width: auto;
    }
  }
}