//
// Shop module styles
// --------------------------------------------------


// Product card

.product-card {
  background-color: transparent;
  border: 0;
  border-radius: 0px;
  transition: $product-card-transition;

  .card-img-top {
    position: relative;
    padding-top: calc(453 /306 * 100%);
    border-radius: 0px !important;

    img {
      position: absolute;
      top: 0px;
      letter-spacing: 0px;
    }
  }
  .product-card-actions,
  > .btn-wishlist,
  .tags {
    position: absolute;
    top: $spacer * .75;
    right: $spacer * .75;
    z-index: 5;
  }
  > .btn-wishlist {
    top: 0.75rem;
    right: 0.45rem;
    background-color: transparent;

    &.btn-sm {
      > i {
        font-size: 1.0125rem;
      }
    }
    #wishlist_list & {
      .counter {
        display: none;
      }
    }
  }
  .product-card-actions .btn-action {
    padding: .5rem;
    transition: $product-card-transition;
    @include border-radius($border-radius-sm);
    background-color: $white;
    font-size: $font-size-ms;
    visibility: hidden;
    opacity: 0;
  }

  .tags {
    right: auto;
    left: $spacer * .75;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &.badge-end {
      right: $spacer * .75;
      left: auto;
      align-items: flex-end;
    }
  }

  .card-body {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px 0px;
    z-index: 2;

    .product-meta {

    }
    .product-title {
      flex-grow: 1;
      margin-bottom: 16px;

      a {
        font-family: $font-family-base;
      }
    }
    .product-price {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: $color-dark;
    }
  }

  .card-body-hidden {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    margin-top: -$card-spacer-y;
    transition: $product-card-transition;
    @include border-radius($card-border-radius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: $card-bg;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
  }

  @include media-breakpoint-up(sm) {
    padding-bottom: 0;
  }
  @include media-breakpoint-up(md) {
    .card-body {
      .product-meta {
        margin-bottom: 7px;
      }
      .product-title {
        margin-bottom: 23px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &:hover:not(.card-static) {
      //border-color: $card-bg !important;
      //box-shadow: $product-card-hover-box-shadow;
      //z-index: 10;
      .product-card-actions .btn-action {
        //opacity: 1;
        //visibility: visible;
      }
      .card-body-hidden {
        //opacity: 1;
        //visibility: visible;
        //box-shadow: $product-card-hover-box-shadow;
      }
    }
  }
}
.ads-card {
  .inner-wrp {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


// List variant

.product_listing {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 20px;
  row-gap: 14px;

  > .full-width {
    grid-column: 1 / 3;
  }
  .product-list-thumb {
    @include border-radius($card-border-radius);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  @include media-breakpoint-up(sm) {
    //grid-template-columns: repeat(3, minmax(0, 1fr));

    > .full-width {
      grid-column: 1 / 4;
    }
    .product-list-thumb {
      width: $product-list-thumb-width;
      @include border-radius($card-border-radius);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;
    }
    .card-body-hidden {
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      box-shadow: none !important;
    }
  }
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: 30px;
    row-gap: 48px;

    > .full-width {
      grid-column: 1 / 5;
    }
    &:hover + .border-top { opacity: 0; }
  }
}


// Alternative product card

.product-card-alt {
  border: 0;

  .card-body { padding: $spacer ($spacer * .625); }

  .product-thumb {
    position: relative;
    @include border-radius($card-border-radius);
    overflow: hidden;

    > img {
      display: block;
      width: 100%;
    }
  }
  
  .product-thumb-overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $product-card-thumb-overlay-bg;
    z-index: 1;
  }

  .btn-wishlist {
    top: $spacer * .75;
    right: $spacer * .75;
    background-color: $white;
    z-index: 5;
    visibility: hidden;
  }
  .badge {
    position: absolute;
    right: auto;
    left: $spacer * .75;
    z-index: 5;
  }

  .product-card-actions {
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;
    z-index: 5;
    visibility: hidden;
  }

  .product-thumb-overlay,
  .btn-wishlist,
  .product-card-actions {
    position: absolute;
    transition: $product-card-transition;
    opacity: 0;
  }

  @include media-breakpoint-up(lg) {
    .product-thumb:hover {
      .product-thumb-overlay,
      .btn-wishlist,
      .product-card-actions {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}


// Product title

.product-title > a {
  transition: $product-title-link-transition;
  color: $product-title-link-color;
  text-decoration: none !important;

  &:hover {
    color: $product-title-link-hover-color;
  }
}


// Product meta

.product-meta {
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  transition: $product-meta-transition;
  color: $color-dark;
  text-decoration: none !important;

  &:hover {
    color: $product-meta-hover-color;
  }
}


// Floating button

.product-floating-btn {
  position: absolute;
  right: .75rem;
  bottom: .75rem;
  transition: opacity .2s ease-in-out;
  opacity: 0;
  z-index: 10;

  .product-card:hover & {
    opacity: 1;
  }
}


// Star rating

.star-rating {
  display: inline-block;
  white-space: nowrap;
  line-height: 1;
  vertical-align: middle;
  .star-rating-icon {
    display: inline-block;
    margin-right: .1875rem;
    color: $star-color;
    font-size: $star-size;
    vertical-align: middle;
    &.active {
      color: $star-active-color;
    }
  }
}

// Shop specific page title that overlaps the content below

.page-title-overlap {
  padding-bottom: 6.375rem;
  & + * {
    margin-top: -4.875rem;
  }
  @include media-breakpoint-down(md) {
    padding-bottom: 5.5rem;
  }
}


// Interactive credit card

.credit-card-wrapper {
  margin: 1.875rem -.9375rem;
  @include media-breakpoint-down(sm) {
    .jp-card-container { width: 16.25rem !important; }
    .jp-card { min-width: 15.625rem !important; }
  }
}

// Poduct availability badge (Single product)

.product-badge {
  position: absolute;
  top: 50%;
  right: -1.75rem;
  padding: {
    top: .425rem;
    left: .625rem;
    right: 1rem;
    bottom: .425rem;
  }
  transform: translateY(-50%);
  @include border-radius($border-radius);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: $font-size-ms;

  &::after {
    position: absolute;
    bottom: -.475rem;
    right: .25rem;
    height: 0;
    width: 0;
    transform: rotate(-45deg);
    border: solid transparent;
    content: " ";
    pointer-events: none;
    border-width: .525rem;
  }

  > i {
    margin: {
      top: -.125rem;
      right: .25rem;
    }
    font-size: 1.2em;
    vertical-align: middle;
  }

  &.product-available {
    background-color: $success;
    color: $white;
    &::after {
      border-color: rgba($success, 0);
      border-bottom-color: darken($success, 10%);
    }
  }
  &.product-not-available {
    background-color: $gray-200;
    color: $gray-700;
    &::after {
      border-color: rgba($gray-200, 0);
      border-bottom-color: darken($gray-200, 8%);
    }
  }
  @include media-breakpoint-down(lg) {
    right: -.75rem;
  }
}
.modal .product-badge { right: -.575rem; }





@include media-breakpoint-down(sm) {
  .product-gallery-block { display: block; }
  .product-gallery-thumblist {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: {
      right: -$spacer;
      left: -$spacer;
    }
    padding-top: $spacer;
  }
  .product-gallery-thumblist-item {
    margin: $product-gallery-thumb-spacing * .5;
  }
}

#products_listing {
  #subcategories-block {
    margin-bottom: 30px;
    overflow: hidden;

    .tns-ovh {
      overflow: visible;
    }
    .subcategory-element {
      .title {
        display: block;
        margin-top: 12px;
        font-weight: 500;
        font-size: 10px;
        line-height: 24px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }
  .product_listing {
    margin-bottom: 46px;

  }
  .pagination-wrp {
    #lazy_load {
      min-width: 100%;
    }
  }
  @include media-breakpoint-up(md) {
    #subcategories-block {
      margin-bottom: 50px;

      .subcategory-element {
        .title {
          margin-top: 10px;
        }
      }
    }
    .pagination-wrp {
      #lazy_load {
        min-width: 250px;
      }
    }
  }
}

#filter {
  #filter_summary {
    .filters-summary-top {
      margin-bottom: 16px;

      .filters-available-mob ul {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding: 0px;

        li {
          flex-grow: 0;
          flex-shrink: 0;
          width: 50%;
          border: 1px solid $color-dark;

          a {
            position: relative;
            display: block;
            padding: 11px 15px;
            font-size: 14px;
            line-height: 24px;
            text-align: center;

            i {
              position: absolute;
              top: 50%;
              left: 12px;
              transform: translateY(-50%);
            }
          }
          &:not(:first-child) {
            border-left: none;
          }
        }
      }
      .results-count-wrp {
        margin: 0px;
        font-weight: 500;
        font-size: 10px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }
  }
  @include media-breakpoint-up(md) {
    #filter_summary {
      .filters-summary-top {
        .filters-available {
          margin-bottom: 15px;

          ul {
            position: relative;
            display: flex;
            flex-direction: row;
            list-style: none;
            margin-bottom: 0px;
            padding: 4px 4px 0px 0px;
            box-shadow: -48px 0px 8px -25px rgb(251, 249, 246) inset;
            overflow: auto;

            li {

              a {
                position: relative;
                display: block;
                padding: 7px 19px;
                height: 40px;
                font-size: 14px;
                line-height: 24px;
                border: 1px solid $color-dark;
                white-space: nowrap;
                //z-index: -1;

                .count {
                  position: absolute;
                  top: -4px;
                  right: -4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 16px;
                  height: 16px;
                  font-weight: 700;
                  font-size: 8px;
                  line-height: 12px;
                  color: $white;
                  background-color: $color-dark;
                  border-radius: 8px;
                }
              }
              &:not(:last-child) {
                padding-right: 8px;
              }
            }
            &::-webkit-scrollbar {
              display: none;
            }
            &:after {
              content: "";
              position: absolute;
              top: 0px;
              bottom: 0px;
              right: 0px;
              display: block;
              width: 4px;
              //background: $primary;
              background: linear-gradient(90deg, rgba(255,255,255,0) 0%, $primary 100%);
            }
          }
          #filter_on {
            position: relative;
            display: flex;
            align-items: center;
            margin: 4px 0px 2px 8px;
            padding: 2px 18px;
            height: 40px;
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            background: none;
            border: 1px solid #222222;

            i {
              margin-right: 13px;
            }
          }
          > button.btn-link {
            margin-top: 2px;
            margin-left: 20px;
            padding: 8px 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0px;
            text-transform: initial;
            text-decoration: none;

            .icon {
              margin-right: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    #filter_summary {
      .filters-summary-top {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: minmax(0, 1fr) auto;
        align-items: center;
        margin-bottom: 36px;

        .filters-available {
          margin-bottom: 2px;
        }
        .results-count-wrp {
          margin-left: 50px;
        }
      }
    }
  }
}
#filtersPopup {
  .content {
    margin-right: -10px;
    padding-right: 10px;
    padding-bottom: 30px;
    @include nice-scroll-params();

    > .widget {
      border-color: $color-dark !important;

      > .widget-title {
        position: relative;
        margin: 0px;
        padding: 20px 0px;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        font-family: $font-family-base;
        cursor: pointer;

        &:after {
          content: "\e906";
          position: absolute;
          right: 0px;
          top: 50%;
          display: block;
          width: 15px;
          height: 15px;
          font-size: 14px;
          font-weight: bold;
          line-height: 15px;
          font-family: "cartzilla-icons" !important;
          transform: translateY(-50%);
        }
      }
      &.open {
        > .widget-title {
          &:after {
            content: "\e90c";
          }
        }
      }
      .btn-link {
        font-size: 14px;
        line-height: 24px;
      }
      .hidden {
        display: none !important;
      }
    }
    > .widget-filter {
      > .widget-filter-list {
        display: none;
        padding-bottom: 12px;

        .widget-filter-item {
          margin-bottom: 10px !important;

          &.active {
            .form-check-input {
              background-color: $color-dark;
              border-color: $color-dark;

              &[type=checkbox] {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
              }
            }
          }
          &:not(.active) {
            .form-check-input {
              background-color: $white;
              border-color: $color-dark;

              &[type=checkbox] {
                background-image: none;
              }
            }
          }
        }

        &.widget-filter-colors {
          grid-template-columns: repeat(6, minmax(0, 1fr));
          column-gap: 13px;
          row-gap: 16px;
          padding-left: 0px;

          .widget-filter-item {
            position: relative;
            display: flex;
            justify-content: center;
            margin-bottom: 0px !important;
            padding-left: 0px;
            min-height: initial;
            width: auto !important;

            .form-check-input[type=checkbox] {
              position: absolute;
              left: 50%;
              top: 50%;
              z-index: 1;
              margin: 0px;
              font-size: 18px;
              background-color: transparent;
              border-color: transparent;
              transform: translate(-50%, -50%);
            }
            .form-option-color {
              position: initial;
              margin-top: 0px;
              margin-left: 0px;
              width: 38px;
              height: 38px;
              transition: all .3s;
            }
            &.active {
              .form-option-color {
                @include box-shadow(0px 0px 0px 2px rgba(255,255,255,1) inset);
                border: 1px solid $color-dark;
              }
            }
          }
        }
      }
      &.open {
        > .widget-filter-list {
          display: block;
          margin-top: -4px;

          &.widget-filter-colors {
            display: grid;
          }
        }
      }
    }
    > .widget-categories {
      #shop-categories {
        margin-top: -12px;
      }
      > .accordion {
        display: none;
        padding-bottom: 12px;
      }
      &.open {
        > .accordion {
          display: block;
        }
      }
    }

    > .widget-prices {
      .widget-prices-list {
        display: none;
        margin-top: -4px;
        padding-bottom: 12px;

        .widget-filter-item {
          margin-bottom: 10px !important;

          .form-check {
            cursor: pointer;

            .check {
              display: block;
              float: left;
              margin-top: 0.25em;
              margin-left: -28px;
              width: 16px;
              height: 16px;
              border: 1px solid $color-dark;
            }
          }

          &.active {
            .form-check {
              .check {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
                background-color: #222222;
                border-color: #222222;
              }
            }
          }
        }
      }
      &.open {
        .widget-prices-list {
          display: block;
        }
      }
    }
  }
}