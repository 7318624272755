.page_editor_template {
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1354px;

  &.mb-0 {
    margin-bottom: 0 !important;
  }
  .object-fit-cover {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 0;

    img {
      max-width: initial;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .object-fit-contain {
    position: absolute;
    width: 100%;
    height: 100%;

    img{
      object-fit: contain;
      object-position: center;
      left: 50%;
      position: relative;
      transform: translatex(-50%);
      width: auto;
      height: 100%;
    }
  }

  #pages_detailed & {
    &.full_width {
      max-width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &.full_width {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;

    .row, .product_listing {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  @include media-breakpoint-down(md) {
    &.video_hide_mobile {
      display: none;
    }
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 50px;

    #pages_detailed & {
      &.full_width {
        padding-left: 63px;
        padding-right: 63px;
      }
    }
    &.video_hide_desktop {
        display: none;
    }
  }
}

@include media-breakpoint-up(md) {
  .d-width-50 {
    width: 50% !important;
  }

  .d-width-25 {
    width: 25% !important;
  }

  .d-width-75 {
    width: 75% !important;
  }
}

.page_editor_banner_right {
  > div {
    &:nth-child(1) {
      order: 2;
    }
    &:nth-child(2) {
      order: 1;
    }

  }
  .texts-wrp{
    padding-left: 0 !important;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;

    .modal-body & {
      flex-direction: column;
    }
  }
}
.page_editor_banner_top {
  @include media-breakpoint-down(sm) {
    > div {
      &.banner-wrp {
        order: 1;
      }
      &.texts-wrp {
        order: 2;
      }
    }
  }
}
.page_editor_banner_bottom {
  @include media-breakpoint-down(md) {//neaisku kam sitas naudojamas
    > div {
      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        order: 1;
      }
    }
  }
  @include media-breakpoint-up(sm) {
    .page_editor_banner_right {
      > div {
        &.banner-wrp {
          order: 2;
        }

        &.texts-wrp {
          order: 1;
        }
      }
    }
  }
}

/** banner-text **/
@include media-breakpoint-up(sm) {//desktop
  .banner_vertical_position_options_top {
    align-items: flex-start;
  }
  .banner_vertical_position_options_middle {
    align-items: center;
  }
  .banner_vertical_position_options_bottom {
    align-items: flex-end;
  }
  .banner_vertical_position_options_crop {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.editor_type_text {
  &.editor_type_title_block {
    font-weight: 300;

    h1, .h1 {
      margin-bottom: 14px;
    }
  }
  @include media-breakpoint-down(md) {
    h2, .h2, h3, .h3 {
      text-align: left;
    }
  }
  @include media-breakpoint-up(md) {
    &.editor_type_title_block {
      h1, .h1 {
        margin-bottom: 19px;
      }
    }
  }
}
.editor_type_banners_slider {
  background: transparent !important;

  .text-wrp {
    .h1, h2, h3, p {
      color: inherit;
    }
  }
  @include media-breakpoint-down(md) {
    h2 {
      font-size: calc(1.375rem + 1.5vw);
      line-height: 1;
    }
    h3 {
      margin-bottom: 8px;

    }
  }
  @include media-breakpoint-up(md) {
    max-width: 100%;
    background-color: #ffffff;

    .tns-outer {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto;
    }
    .banner-outer-wrp {
      .banner-wrp {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        height: inherit;

        img {
          max-width: initial;
          height: inherit;
          grid-column-start: 2;
          grid-column-end: 3;
        }
        &.text-left {
          img {
            margin-left: auto;
          }
        }
        &.text-right {
          direction: rtl;

          img {
            margin-right: auto;
          }
        }
      }
      .text-outer-wrp {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        padding: 40px 0px;

        .text-wrp {
          h3 {
            margin-bottom: 0px;
            font-size: 28px;
            line-height: 30px;
            color: inherit;
            transition: all .3s;
          }
          h2 {
            font-size: 34px;
            line-height: 34px;
            transition: all .3s;
          }
          .description {
            font-size: 14px;
            line-height: 18px;

            p {
              transition: all .3s;

              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
          &.content-right {
            grid-column-start: 2;
            grid-column-end: 3;
            text-align: right;
          }
          &.content-left {
            text-align: left;
          }
        }
      }

      &.img-full {
        .banner-wrp {
          position: initial;
          display: block;
          text-align: center;

          img {
            max-width: 1920px;
            width: 100%;
            height: auto;
          }
        }
        > .container {
          position: absolute;
          top: 0px;
          bottom: 0px;
          left: 0px;
          right: 0px;

          .text-outer-wrp {
            padding: 40px 50px;
          }
        }
      }
    }
    .tns-controls {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      display: block;
      max-width: 1920px;
      width: 100%;
      height: 52px;
      transform: translate(-50%, -50%);
    }
    .banner-outer-wrp {}
    .tns-inner {
      margin: 0px !important;

      .tns-carousel-inner {
        text-align: center;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .banner-outer-wrp {
      .text-outer-wrp {
        .text-wrp {
          h3 {
            font-size: 36px;
            line-height: 38px;
          }
          h2 {
            font-size: 42px;
            line-height: 42px;
          }
          .description {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    .banner-outer-wrp {
      .text-outer-wrp {
        .text-wrp {
          h3 {
            margin-bottom: 8px;
          }
          h2 {}
          .description {
            font-size: 16px;
            line-height: 20px
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xxxl) {
    .banner-outer-wrp {
      .text-outer-wrp {
        .text-wrp {
          h3 {}
          h2 {
            font-size: 48px;
            line-height: 48px;
          }
          .description {
            font-size: 16px;
            line-height: 27px
          }
        }
      }
    }
  }
}
.editor_type_banner {
  margin-bottom: 40px;
  padding: 0px;

  a {
    &:hover {
      text-decoration: none;
    }
  }
  .banner-inner-wrp, .fake-banner-inner-wrp {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 20px;
    min-height: 222px;

    &.ha-left {
      justify-content: flex-start;
    }
    &.ha-center {
      justify-content: center;
    }
    &.ha-right {
      justify-content: flex-end;
    }
    &.no-min-height {
      padding: 20px 0px 0px 0px;
      min-height: initial !important;
    }
  }
  &.full_width {
    .fake-banner-inner-wrp + .banner-inner-wrp {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  .banner-content-wrp {
    z-index: 1;
    width: auto !important;

    .subtitle {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    h2 {
      margin: 0px;
      margin-bottom: 12px;
      font-size: 32px;
      line-height: 40px;
      text-transform: initial;
      color: inherit;
    }
    p {
      margin-bottom: 0;
      font-size: 10px;
      line-height: 24px;
      letter-spacing: 0.1em;
    }
    button {
      padding-left: 0;
      padding-right: 0;
    }
    &.fonts-1 {
      h2 {
        margin-bottom: 5px;
        font-size: 32px;
        line-height: 37px;
      }
      p {
        margin-bottom: 0px;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  &.ha-left {
    .banner-inner-wrp {
      background-position: left center;
    }
  }
  &.ha-center {
    .banner-inner-wrp {
      background-position: center;
    }
  }
  &.ha-right {
    .banner-inner-wrp {
      background-position: right center;
    }
  }
  @include media-breakpoint-down(lg) {
    //&:not(.full_width) {
    //  padding-left: 0px;
    //  padding-right: 0px;
    //}
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 55px;

    .banner-inner-wrp, .fake-banner-inner-wrp {
      padding: 70px;
      min-height: 540px;

      &.no-min-height {
        padding-top: 30px;
        padding-bottom: 0px;
      }
    }
    .banner-content-wrp {
      h2 {
        margin-bottom: 10px;
        font-size: 40px;
        line-height: 40px;
      }
      p{}
      button {}
      &.fonts-1 {
        h2 {
          margin-bottom: 19px;
          font-size: 76px;
          line-height: 80px;
          letter-spacing: 0px;
        }
        p {
          font-size: 14px;
          line-height: 26px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &.full_width {
      .fake-banner-inner-wrp + .banner-inner-wrp {
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }
}
.editor_type_banner_text  {
  .inner-wrp {
    flex-direction: column;

    > div {
      width: 100%;
    }
    .banner-wrp {
      position: relative;
      height: 290px;

      &.shadow {
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3) !important;
      }
      &.keep-ratio {
        height: auto;
      }
    }
    .texts-wrp {
      padding: 42px 32px 0px 32px;
      text-align: center;

      h2 {
        margin-top: 0px;
        margin-bottom: 23px;
        text-transform: initial;
        color: inherit;
      }
      .text-wrp {
        p {
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
      &.fonts-1 {
        line-height: 22px;
        font-size: 15px;
      }
      &.margin-tb {
        margin: 0px 8px;
        width: calc(100% - 16px);
      }
    }
  }
  @include media-breakpoint-down(md) {
    padding-left: 14px;
    padding-right: 14px;

    .inner-wrp {
      .texts-wrp {
        padding-left: 0;
        padding-right: 0;
      }
      &.page_editor_banner_bottom {
        .texts-wrp {
          padding: 0px 0px 42px 0px;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .inner-wrp {
      flex-direction: row;

      > div {
        width: 50%;
      }
      .banner-wrp {
        height: auto;
        padding: 0px;

        &.wide {
          width: 58.3%;
        }
        & + .texts-wrp {
          padding-bottom: 30px;
        }
        & + .texts-wrp:not(.has_btn) {
          padding-bottom: 0;
          margin-bottom: 0;

          p:last-child {
            margin-bottom: 0;
          }
        }
      }
      .texts-wrp {
        padding: 0 32px;
        padding-bottom: 0;
        text-align: left;

        .text-wrp {}
        &.fonts-1 {
          line-height: 30px;
          font-size: 17px;
        }
        &.margin-tb {
          margin: 28px 0px;
          width: 41.7%;
        }
        &.has_btn {
          padding-bottom: 0;
        }
      }
    }
  }
}


.editor_type_collection {
  margin-bottom: 72px;

  @include media-breakpoint-down(md) {
    margin-bottom: 40px;

    .collections_index_listing {
      .content-wrp {
        margin-bottom: 0 !important;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 65px;
  }
}

.editor_type_features  {
  h2 {
    margin-bottom: 12px;
  }
  h3 {
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-family: $font-family-base;
  }
  .features-wrp {
    margin-top: 32px;
    margin-bottom: 20px;

    .tns-carousel-inner-new {
      display: flex;
    }
    .feature-wrp {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 34px 20px;
      min-height: 100%;
      background-color: $white;

      img {
        margin-bottom: 15px;
        max-width: 64px;
      }
      h4 {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
      }
      p {
        margin-bottom: 0px;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    > .container {
      padding: 0px;
    }
  }
  @include media-breakpoint-up(lg) {
    .features-wrp {
      margin-top: 38px;
      margin-bottom: 46px;

      .tns-carousel {
        .tns-carousel-inner-new {
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));
          column-gap: 30px;
          row-gap: 30px;

          .feature-wrp {
            padding: 10px 20px;
            background-color: transparent;
          }
        }
      }
    }
  }
}

.editor_type_banner_banner {
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .inner-wrp {
    display: flex;
    flex-direction: column;

    .banner-wrp {
      margin-bottom: 24px;
      +.banner-wrp {
        @include media-breakpoint-down(md) {
          margin-top: -24px;
          margin-bottom: 0;
        }
      }
      .banner-inner-wrp, .fake-banner-inner-wrp {
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 23px;
        padding-bottom: 0;
        min-height: 320px;
        text-align: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        h3, p, span[role="button"] {
          position: relative;
          z-index: 1;
        }
        h3 {
          margin: 0px 0px 10px 0px;
          font-weight: normal;
          font-size: 24px;
          line-height: 32px;
          color: inherit;
        }
        p {
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 16px;
        }
        span[role="button"] {
          margin-top: 8px;
          font-size: 14px;
          line-height: 24px;
          text-decoration: underline;
          color: inherit;
        }
        &.no-min-height {
          padding-left: 0px;
          padding-right: 0px;
          min-height: initial !important;
        }
        &.text-start {
          align-items: start;
        }
        &.text-end {
          align-items: end;
        }
      }
    }
    &.wt_gap {
      gap: 25px;
    }
  }
  @include media-breakpoint-up(md) {
    .inner-wrp {
      flex-direction: row;

      .banner-wrp {
        margin-bottom: 0px;
        width: 50%;

        .banner-inner-wrp, .fake-banner-inner-wrp {
          padding: 30px;
          padding-bottom: 0;
          min-height: 400px;

          h3 {
            margin: 0px 0px 18px 0px;
            font-size: 28px;
            line-height: 24px;
          }
          p {
            margin-bottom: 14px;
          }
        }
      }
      &.wt_gap {
        column-gap: 30px;
      }
    }
  }
}

.editor_type_texts_banner {
  .inner-wrp {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;

    .banner-wrp {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      position: relative;
      overflow: hidden;
      margin: -40px 27px 0px 27px;
      min-height: 290px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: initial;
        min-height: 100%;
        min-width: 100%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }
    .text-wrp {
      &.text-top-wrp {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        padding: 45px 28px 75px 28px;
        background-color: #F9F9FB;

        h1 {
          margin-top: 0px;
          margin-bottom: 9px;
        }
        p {
          line-height: 20px;
        }
      }
      &.text-bottom-wrp {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        padding: 54px 28px 32px 28px;
      }
    }
  }
  @include media-breakpoint-up(md) {
    .inner-wrp {
      grid-template-columns: 50% 50%;
      grid-template-rows: auto auto;

      .banner-wrp {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        margin: 0px;

        img {
          width: auto;
        }
      }
      .text-wrp {
        &.text-top-wrp {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          padding: 96px 69px 79px 69px;

          .inner-text-wrp {
            max-width: 380px;
          }
          h1 {
            margin-bottom: 19px;
          }
          p {
            line-height: 26px;
          }
        }
        &.text-bottom-wrp {
          grid-column: 1 / 2;
          grid-row: 2 / 3;
          padding: 101px 69px 75px 69px;

          .inner-text-wrp {
            max-width: 500px;
          }
        }
      }
    }
  }
}

.editor_type_categories {
  .inner-wrp {
    .banner-block-wrp {
      display: none;
    }
    .categories-wrp {
      h2 {
        margin-bottom: 14px;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        font-family: $font-family-base;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
      ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 0px;
        border: none;

        li {
          display: flex;
          justify-content: center;
          margin: {
            top: 17px;
            bottom: 17px;
          }
          padding: 0px 10px;
          width: 50%;

          a {
            display: grid;
            justify-content: center;
            row-gap: 8px;
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            text-align: center;
            font-family: $font-family-heading;

            img {
              height: 40px;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .inner-wrp {
      .categories-wrp {
        ul {
          li {
            width: calc(100% / 3);
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top: 65px;

    .inner-wrp {
      display: grid;
      grid-template-columns: 280px auto 280px;
      column-gap: 30px;

      .banner-block-wrp {
        position: relative;
        display: flex;

        > div {
          position: absolute;
        }
        &:first-child {
          align-items: end;

          > div {
            left: 0px;
            bottom: 0px;
          }
        }
        &:last-child {
          justify-content: end;

          > div {
            top: 0px;
            right: 0px;
          }
        }
      }
      .categories-wrp {
        margin-bottom: 90px;
        padding-top: 20px;

        ul {
          li {
            margin: {
              top: 6px;
              bottom: 18px;
            }
            width: 100%;

            a {
              font-size: 40px;
              line-height: 48px;

              img {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .inner-wrp {
      grid-template-columns: 356px auto 356px;
    }
  }
}

.editor_type_running_line {
  margin: 40px 0px !important;

  .marquee {
    position: relative;
    display: flex;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;

    .inner-wrp {
      display: grid;
      grid-auto-flow: column;
      animation-duration: 5s;
      animation-timing-function: linear;
      animation-name: changePos;
      animation-iteration-count: infinite;
      animation-direction: normal;

      ul {
        list-style: none;
        display: grid;
        grid-auto-flow: column;
        column-gap: 36px;
        margin: 0px;
        padding: 0px 18px;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        font-family: $font-family-heading;
        border: none;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .marquee {
      .inner-wrp {
        ul {
          column-gap: 80px;
          padding: 0px 40px;
          font-size: 40px;
          line-height: 52px;
        }
      }
    }
  }
}

.editor_type_duk  {
  .accordion-item {
    .accordion-body {
      ul {
        padding-left: 24px;
      }
    }
  }
}

.banner-slider-inner-wrp {
  padding-left: 20px;
  padding-right: 20px;
}

.main-banners-slider {
  @include media-breakpoint-down(md) {
    .banner_text_on_top {
      position: absolute !important;
      top: 50%;
      left: 0;
      right: 0;
      transform: translatey(-50%);
    }
  }
}