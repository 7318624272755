#profile-menu {
  .dropdown-toggle {
    &:after {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {

    .dropdown-menu {
      margin-right: 5px;
      padding: 31px 35px 28px 35px;
      width: 222px;

      li {
        margin: 2px 0px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        a {
          display: block;
          padding: 5px 0px;
        }
        &.title-wrp {
          margin: 0px 0px 23px 0px;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &.logout-wrp {
          margin-top: 26px;

          &:before {
            content: "";
            display: block;
            margin-bottom: 27px;
            height: 1px;
            width: 32px;
            background-color: $link-color;
          }
        }
      }
    }
  }
}