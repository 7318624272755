#quick_search {
  #search_block {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background: $primary;

    .icon {
      color: $link-color;
    }
    #search_form {
      border-bottom: 1px solid $link-color;

      form {
        display: grid;
        grid-template-columns: 1fr auto;
        padding: 9px 0px 8px 0px;

        .input-group {
          display: flex;

          button {
            border: none !important;
            background: transparent !important;
            padding: 0px 3px 0px 0px;

            i {
              font-size: 20px;
            }
          }
          input {
            padding: 14px 9px;
            background: $primary;
            border: none;
            box-shadow: none;
          }
        }
        .act-hide-quickSearch {
          .icon {
            font-size: 24px;
            line-height: 50px;

            &:before {
              margin: 0px;
            }
          }
        }
      }
    }
    #search_suggestions_block {
      max-height: calc(100vh - 68px);
      overflow: auto;

      .results-count {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
      .no-result-text {
        margin: 20px 0px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        font-family: $font-family-basier-squere;
      }
      .search-populars-wrp {
        padding-bottom: 40px;
        overflow: hidden;

        .block-title {
          margin-top: 40px;
          margin-bottom: 20px;
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          font-family: $font-family-basier-squere;
        }

        .search-popular-products-wrp {
          .product_listing {
            display: grid;
          }
        }
      }

      .suggestions-list-head {
        margin: 18px 0px;

        h3, a {
          margin: 0px;
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          font-family: $font-family-basier-squere;
        }
        a {
          text-decoration: underline;
        }
      }
    }
    .show-all-btn {
      width: 100%;
    }
  }

  &.open {
    position: relative;
    z-index: 10;

    #search_block {
      display: block;
      z-index: 11;
    }
  }
  @include media-breakpoint-down(md) {
    #search_block {
      #search_suggestions_block {
        .search-populars-wrp {
          .search-popular-categories-wrp {
            margin-bottom: 40px;

            .block-title {
              margin-top: 24px;
            }
            .swiper-search-categories {
              overflow: visible;

              .swiper-slide {
                width: auto;

                a {
                  display: inline-block;
                  margin-bottom: 20px;
                  padding: 8px 16px 6px 16px;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  white-space: nowrap;
                  border: 1px solid $link-color;
                  border-radius: 25px;
                }
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    #search_block {
      .show-all-btn {
        width: auto;
        min-width: 198px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    #search_block {
      #search_form {
        form {
          padding: 12px 0px;

          .input-group {
            button {
              padding: 0px;
              i {
                font-size: 20px;
              }
            }
            input {
              font-size: 20px;
              line-height: 24px;
            }
          }
          .act-hide-quickSearch {
            .icon {
              font-size: 23px;
              line-height: 50px;
            }
          }
        }
      }
      #search_suggestions_block {
        .results-count {
          font-size: 20px;
          line-height: 36px;
        }
        .search-populars-wrp {
          padding-bottom: 60px;

          .block-title {}
          .search-popular-categories-wrp {
            .swiper-search-categories {
              .swiper-wrapper {
                flex-direction: column;

                .swiper-slide {
                  a {
                    display: block;
                    margin: 6px 0px;
                    padding: 3px 0px;
                    color: $link-color;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                  }
                }
              }
            }
          }
          .search-popular-products-wrp {
            .product_listing {
              grid-template-columns: repeat(3, minmax(0, 1fr));

              &.columns-4 {
                grid-template-columns: repeat(4, minmax(0, 1fr));
              }
            }
          }
        }
      }
    }
  }
}