#departments-top-wrp {
  display: grid;
  row-gap: 48px;
  margin-top: 49px;
  margin-bottom: 48px;

  .left-block-wrp {
    .left-block-top-wrp {
      margin-bottom: 30px;

      .subtitle {
        display: block;
        margin-bottom: 7px;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
      h1 {
        margin-bottom: 28px;
        font-weight: 400;
        font-size: 32px;
        line-height: 36px;
      }
      .description-wrp {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
    .left-block-bottom-wrp {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      ul {
        list-style: none;
        display: grid;
        row-gap: 14px;
        margin: 0px;
        padding: 0px;

        li {
          a {
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  .center-block-wrp {
    picture {
      display: block;
    }
  }
  .right-block-wrp {
    .title {
      margin-bottom: 19px;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
    }
    ul {
      list-style: none;
      display: grid;
      row-gap: 12px;
      margin: 0px;
      padding: 0px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  @include media-breakpoint-up(md) {
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;

    .left-block-wrp {
      .left-block-top-wrp {
        .subtitle {}
        h1 {
          font-size: 40px;
          line-height: 48px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 30px;
    margin-top: 75px;
    margin-bottom: 96px;
    max-width: initial;

    .left-block-wrp {
      display: flex;
      flex-direction: column;

      .left-block-top-wrp {
        flex-grow: 1;
      }
      .left-block-bottom-wrp {
        flex-shrink: 0;
      }
    }
    .right-block-wrp {
      display: flex;
      flex-direction: column;
      justify-content: end;
    }
  }
  @include media-breakpoint-up(xl) {
    column-gap: 112px;
  }
}

#departments_listing {
  padding: 56px 0px;

  .elements_listing {
    display: grid;
    row-gap: 56px;

    .element {
      .card-img-top {
        display: block;
        margin-bottom: 20px;
        border-radius: 0px !important;
      }
      .text-wrp {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $color-dark !important;

        a {
          text-decoration: underline;
        }
        > h6 {
          margin-bottom: 20px;
          font-size: 24px;
          line-height: 36px;
        }
        ul {
          list-style: none;
          display: grid;
          row-gap: 20px;
          margin: 0px;
          padding: 0px;

          li {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .elements_listing {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 30px;
    }
  }
  @include media-breakpoint-up(lg) {
    padding: 96px 0px;

    .elements_listing {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 30px;
    }
  }
  @include media-breakpoint-up(xl) {
    .elements_listing {
      column-gap: 109px;
      row-gap: 100px;
    }
  }
}