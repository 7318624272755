#orders_listing, #orders_detailed {
  .badge {
    padding: 10px 16px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: $white;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: 0px;
  }
}
#orders_listing {
  h2 {
    margin: 0px -20px 8px -20px;
    padding: 24px 20px 28px 20px;
    font-size: 24px;
    line-height: 32px;
    background-color: $white;
  }
  .orders-list {
    display: grid;
    column-gap: 8px;
    row-gap: 8px;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    .order-element {
      display: grid;
      grid-template-areas: "photo"
                           "texts";
      row-gap: 16px;
      margin: {
        left: -20px;
        right: -20px;
      }
      background-color: $white;

      .photo-wrp {
        grid-area: photo;
        padding: 20px 20px 0px 20px;
        height: 245px;
        overflow: hidden;
        text-align: center;
      }
      .texts-wrp {
        grid-area: texts;

        .top-wrp {
          padding: 0px 20px 15px 20px;
          border-bottom: 1px solid rgba(34, 34, 34, 0.12);

          .order-nr {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }
          .badge {
            padding: 10px 16px;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: $white;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            border-radius: 0px;
          }
        }
        .bottom-wrp {
          display: grid;
          grid-template-areas: "type items_cnt"
                               "date sum";
          grid-template-columns: 52% auto;
          padding: 20px 20px 24px 20px;
          column-gap: 20px;
          row-gap: 8px;
          font-size: 14px;
          line-height: 24px;

          .type {
            grid-area: type;
          }
          .items-cnt {
            grid-area: items_cnt;
          }
          .date {
            grid-area: date;
          }
          .sum {
            grid-area: sum;
            font-weight: 600;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .orders-list {
      .order-element {
        grid-template-areas: "photo texts";
        grid-template-columns: 166px auto;
        column-gap: 32px;

        .photo-wrp {
          padding: 16px 0px 16px 16px;
          height: initial;
        }
        .texts-wrp {
          padding: 40px 16px 40px 0px;

          .top-wrp {
            position: relative;
            padding: 0px;
            margin-bottom: 32px;
            padding-bottom: 32px;
            border: none;

            .order-nr {
              font-size: 14px;
              line-height: 16px;

              span {
                margin-top: 8px;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
              }
            }
            .badge {
              position: absolute;
              top: -40px;
              right: -16px;
            }
            &:after {
              content: "";
              position: absolute;
              bottom: 0px;
              left: 0px;
              width: 32px;
              height: 1px;
              background-color: $color-dark;
            }
          }
          .bottom-wrp {
            padding: 0px;
            grid-template-columns: 1fr;
            grid-template-areas: "date"
                                 "items_cnt"
                                 "sum";
            row-gap: 0px;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;

            .date {
              margin-bottom: 6px;
              letter-spacing: 0.1em;

              span {
                margin-right: 4px;
                text-transform: uppercase;
              }
            }
            .items-cnt {
              margin-bottom: 24px;
              font-size: 10px;
              line-height: 12px;
              letter-spacing: 0.1em;
            }
            .sum {
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    h2 {
      margin: 0px 0px 8px 0px;
      padding: 32px 40px;
      font-size: 40px;
      line-height: 48px;
    }
    .orders-list {
      .order-element {
        margin: 0px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .orders-list {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .order-element {}
    }
  }
}
#orders_detailed {
  max-width: 1090px;
  margin-left: auto;
  margin-right: auto;

  .title-wrp {
    margin-bottom: 28px;

    h1 {
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
    }
  }
  .order-type-wrp {
    margin-top: -14px;
    margin-bottom: 30px;

    span {
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }
  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    font-family: $font-family-base;
  }
  #prepayment-details, #bill-details {
    margin-bottom: 12px;
    padding: 24px 20px;
    background-color: $white;
  }
  #order-information-block {
    display: grid;
    row-gap: 2px;
    margin-bottom: 12px;

    .order-info-wrp {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      column-gap: 2px;
      row-gap: 2px;

      .receiver-info-wrp, .delivery-method-wrp, .delivery-info-wrp, .payment-info-wrp {
        padding: 24px 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        background-color: $white;
      }
      .receiver-info-wrp {}
      .delivery-method-wrp {}
      .delivery-info-wrp {}
      .payment-info-wrp {}
    }
    .order-rent-wrp {
      display: grid;
      column-gap: 2px;
      row-gap: 2px;

      .return-ending-wrp, .early-return-wrp {
        padding: 24px 20px;
        background-color: $white;

        p {
          font-size: 14px;
          line-height: 24px;
        }
      }
      .return-ending-wrp {
        i {
          font-size: 20px;
        }
        .texts-wrp {}
      }
      .early-return-wrp {
        display: flex;
        flex-direction: column;

        .btn-wrp {
          margin-top: 15px;

          .btn {
            width: 100%;
          }
        }
      }
    }
    .order-actions-wrp {
      display: grid;
      row-gap: 8px;
      padding: 24px 20px;
      background-color: $white;

      .btn {
        width: 100%;
      }
    }
  }
  #order-items-summary {
    display: grid;
    grid-template-areas: "title"
                           "rent-duration"
                           "items"
                           "summary";
    padding: 24px 20px 40px 20px;
    background-color: $white;

    #cart_items {
      grid-area: items;
      margin-top: 20px;
      margin-bottom: 26px;
      padding-bottom: 32px;
      border-bottom: 1px solid rgba(34, 34, 34, 0.12);

      .cart-items-group {
        display: grid;
        row-gap: 28px;
        margin: 0px;
        padding: 0px;

        .order-item-element {
          display: grid;
          grid-template-columns: 80px auto;
          column-gap: 20px;

          .photo-wrp {}
          .texts-wrp {
            display: flex;
            flex-direction: column;
            padding: 7px 0px;

            .brand-title {
              margin-bottom: 4px;
              font-weight: 500;
              font-size: 10px;
              line-height: 12px;
              letter-spacing: 0.1em;
              text-transform: uppercase;
            }
            .product-title {
              flex-grow: 1;
              margin-bottom: 14px;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
            }
            .item-title {
              margin-bottom: 4px;
              font-size: 14px;
              line-height: 22px;
              color: #8D8D8D;
            }
            .price-wrp {
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
    }
    .summary-title {
      grid-area: title;
      position: relative;
      margin-bottom: 24px;
      padding-bottom: 24px;
      font-size: 20px;
      line-height: 26px;

      &:after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 32px;
        height: 1px;
        background-color: $color-dark;
      }
    }
    .summary-rent-duration-wrp {
      grid-area: rent-duration;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;

      h4 {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 16px;
        font-family: $font-family-base;
      }
      p {
        font-size: 14px;
        line-height: 16px;
      }
    }
    .summary-lines {
      grid-area: summary;

      li {
        margin-bottom: 11px;

        &.line-total {
          margin-top: 13px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    #prepayment-details, #bill-details {
      padding: 40px 32px;
    }
    #order-information-block {
      margin-bottom: 16px;

      .order-info-wrp {
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .receiver-info-wrp, .delivery-method-wrp, .delivery-info-wrp, .payment-info-wrp {
          padding: 40px 32px;
        }
      }
      .order-rent-wrp {
        grid-auto-flow: column;
        grid-auto-columns: minmax(0, 1fr);

        .return-ending-wrp, .early-return-wrp {
          padding: 40px 32px;
        }
        .return-ending-wrp {}
        .early-return-wrp {
          flex-direction: row;
          justify-content: space-between;

          .btn-wrp {
            margin-top: 0px;
            margin-left: 20px;

            .btn {
              width: auto;
            }
          }
        }
      }
      .order-actions-wrp {
        display: flex;
        justify-content: center;
        padding: 40px 28px;

        .btn {
          margin: 0px 12px;
          max-width: 320px;
        }
      }
    }
    #order-items-summary {
      grid-template-areas: "items title"
                           "items rent-duration"
                           "items summary";
      grid-template-columns: auto 328px;
      grid-template-rows: auto auto 1fr;
      column-gap: 2px;
      padding: 0px;

      #cart_items {
        margin: 0px;
        padding: 40px 30px 40px 40px;
        border-right: 2px solid $primary;
        border-bottom: none;

        .cart-items-group {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: 30px;
          row-gap: 30px;

          .order-item-element {
            display: flex;
            flex-direction: column;

            .photo-wrp {
              margin-bottom: 17px;
            }
            .texts-wrp {
              flex-grow: 1;
              padding: 0px;

              .price-wrp {
                font-weight: 400;
              }
            }
          }
        }
      }
      .summary-title {
        margin: 40px;
        padding-bottom: 40px;
        font-size: 28px;
        line-height: 36px;
      }
      .summary-rent-duration-wrp {
        margin: 0px 40px;
        padding-bottom: 40px;

        &:after {
          content: "";
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 32px;
          height: 1px;
          background-color: $color-dark;
        }
      }
      .summary-lines {
        padding: 38px 40px 40px 40px;

        li {
          margin-bottom: 3px;

          &.line-total {
            margin-top: 5px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .title-wrp {
      display: flex;
      align-items: center;

      h1 {
        margin-right: 24px;
        margin-bottom: 0px;
        font-size: 40px;
        line-height: 48px;
      }
    }
    #order-information-block {
      .order-info-wrp {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
    #order-items-summary {
      #cart_items {
        .cart-items-group {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    #order-items-summary {
      #cart_items {
        .cart-items-group {
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }
      }
    }
  }
}

.thank-you-page {
  padding-top: 10px;
  max-width: 866px;
  margin-left: auto;
  margin-right: auto;

  .order-success-texts {
    margin-left: auto;
    margin-right: auto;
    max-width: 514px;

    .slogan {
      margin-bottom: 18px;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    h1 {
      font-size: 32px;
      line-height: 36px;
    }
    p {
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.success-important-info {
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
  .elements-wrp {
    display: grid;
    row-gap: 22px;
    margin-left: auto;
    margin-right: auto;
    max-width: 514px;

    .element-wrp {
      display: block;

      h3 {}
    }
  }

  @include media-breakpoint-up(lg) {
    .elements-wrp {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 46px;
      row-gap: 46px;
      max-width: initial;
    }
  }
}