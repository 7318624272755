#cart_detailed {
  h1 {
    margin-top: 28px;
    margin-bottom: 20px;
  }

  @include media-breakpoint-up(lg) {
    h1 {
      margin-top: 32px;
      margin-bottom: 28px;
    }
  }
}
.content_layout_checkout {
  .block-title {
    margin-bottom: 0px;
    padding: 20px;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    border-bottom: 2px solid $primary;
  }

  @include media-breakpoint-down(lg) {
    & + footer.footer {
      padding-bottom: 120px;
    }
  }
  @include media-breakpoint-up(lg) {
    .block-title {
      font-size: 28px;
      line-height: 37px;
      border-bottom: none;
    }
  }
}

#cart_detailed, #delivery_options, #cart_confirm {
  .container {
    max-width: 1130px;
  }
}
.cart-layout-wrp {
  display: grid;
  grid-template-columns: 1fr;

  .content-section {}
  .summary-section {
    > div {
      padding: 32px 20px 40px 20px;
    }
    #delivery_options &, #cart_confirm & {
      > div {
        padding: 0px;
        background-color: $white;
      }
      .cart-summary-header {}
      .cart-item-element {}
    }
  }
  &.no-items {
    grid-template-columns: auto;
  }
  @include media-breakpoint-down(lg) {
    .summary-section {
      #delivery_options &, #cart_confirm & {
        position: fixed;
        top: calc(100% - 80px);
        left: 0px;
        right: 0px;
        height: 100vh;
        z-index: $zindex-fixed;
        background-color: $white;
        box-shadow: 0 0.3rem 1.525rem -0.375rem rgb(0, 0, 0, 0.45) !important;
        transition: all .3s;

        .cart-summary-header {
          background-color: $primary;
          cursor: pointer;

          .content-closed {
            display: grid;
            grid-template-columns: auto minmax(141px, 39.16%);

            .items-wrp {
              padding: 6px;
              overflow: hidden;

              ul {
                list-style: none;
                display: grid;
                margin: 0px;
                padding: 0px;
                grid-template-columns: repeat(4, 48px);
                column-gap: 2px;
              }
            }
            .text-wrp {
              position: relative;
              display: flex;
              flex-direction: column;
              padding-right: 55px;
              font-weight: 500;
              text-align: right;
              justify-content: center;

              .total-items {
                font-size: 10px;
                line-height: 16px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
              }
              .total-sum {
                font-size: 16px;
                line-height: 20px;
              }
              &:after {
                content: "\e906";
                position: absolute;
                top: 50%;
                right: 20px;
                display: block;
                width: 15px;
                height: 15px;
                font-size: 14px;
                font-weight: bold;
                line-height: 15px;
                font-family: "cartzilla-icons" !important;
                transform: translateY(-50%) rotate(180deg);
              }
            }
          }
          .content-open {
            display: none;

            .text-wrp {
              display: grid;
              grid-template-columns: 1fr auto;
              column-gap: 20px;
              align-items: center;
              padding: 20px;
              min-height: 72px;

              span {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
              }
              i {
                font-size: 20px;
                color: $color-dark;
              }
            }
          }
        }
        .cart-summary-body {
          display: flex;
          flex-direction: column;
          overflow: auto;
          height: calc(100vh - 72px);

          > ul {
            padding: 32px 20px;
            border-bottom: 1px solid $gray-300;
          }
          #cart_items {
            .cart-items-group {
              margin-bottom: 0px;
              border-bottom: 1px solid $gray-300;

              .group-header-wrp {
                .package-title {
                  margin: 0px 0px 5px 0px;
                  padding: 0px;
                }
              }
              .group-body-wrp {
                display: block !important;

                .cart-item-element {
                  .text-wrp {
                    .product-title-wrp {
                      .product-title {
                        color: $color-dark;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.open {
        #delivery_options &, #cart_confirm & {
          top: 0px;

          .cart-summary-header {
            .content-closed {
              display: none;
            }
            .content-open {
              display: block;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    main:not(.content_layout_checkout) {
      grid-template-columns: auto 370px;
      column-gap: 30px;
    }

    .summary-section {

    }
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: auto 418px;
    column-gap: 30px;

    .summary-section {
      > div {
        padding: 32px 32px 40px 32px;
      }

      #delivery_options &, #cart_confirm & {
        .cart-summary-header {
          display: none;
        }
        .cart-summary-body {
          display: flex;
          flex-direction: column-reverse;

          #cart_items {
            .cart-items-group {
              margin: 0px;
              padding: 0px;
              border-bottom: 1px solid $primary;

              .group-header-wrp {
                position: relative;
                display: grid;
                grid-template-columns: auto 1fr 20px;
                column-gap: 20px;
                align-items: center;
                margin: 0px;
                padding: 20px 32px;
                cursor: pointer;

                .package-title {
                  margin: 0px;
                }
                .group-duration {
                  margin: 0px;
                }
                &:after {
                  content: "\e906";
                  position: absolute;
                  top: 50%;
                  right: 38px;
                  display: block;
                  width: 10px;
                  height: 10px;
                  font-size: 10px;
                  font-weight: bold;
                  line-height: 15px;
                  font-family: "cartzilla-icons" !important;
                  transform: translateY(-50%);
                }
              }
              .group-body-wrp {
                display: none;
                padding: 15px 32px;

                .cart-item-element {
                  grid-template-columns: 80px auto;

                  .text-wrp {
                    grid-template-rows: 1fr auto auto;
                    grid-template-areas: "title title"
                                         "size size"
                                         "price actions";
                    padding-top: 0px;
                    padding-bottom: 7px;

                    .price-wrp {
                      text-align: left;
                    }
                  }
                }
              }
            }
          }
          > ul {
            padding: 32px;

            .line-total {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}


#cart_items, .order-confirm-items {
  .package-title {
    margin-top: 32px;
    padding: 0px 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  .cart-items-group {
    margin-bottom: 32px;
    padding: 24px 20px 28px 20px;
    background-color: $white;

    .group-header-wrp {
      margin-bottom: 20px;

      .group-name {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
      }
      .group-duration {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  .cart-item-element {
    display: grid;
    grid-template-columns: 80px auto;
    column-gap: 20px;

    .text-wrp {
      display: grid;
      grid-template-rows: 1fr auto auto;
      grid-template-areas: "title title"
                           "size size"
                           "price actions";
      padding-bottom: 7px;

      .product-title-wrp {
        grid-area: title;
        display: flex;
        flex-direction: column;

        .brand-title {
          font-weight: 500;
          font-size: 10px;
          line-height: 24px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
        }
        .product-title {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          font-family: $font-family-base;
        }
      }
      .size-wrp {
        grid-area: size;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        opacity: 0.5;
      }
      .price-wrp {
        grid-area: price;
        color: $color-dark;

        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
        }
      }
      .actions-wrp {
        grid-area: actions;

        .remove-link {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          text-decoration: underline;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 28px;
    }
  }
  @include media-breakpoint-up(lg) {
    .package-title {
      margin-top: 40px;
      padding: 0px;
    }
    .cart-items-group {
      padding: 32px 48px 40px 48px;

      .group-header-wrp {
        display: flex;

        .group-name {
          margin-right: 24px;
        }
      }
    }
    .cart-item-element {
      grid-template-columns: 150px auto;
      column-gap: 32px;

      .text-wrp {
        grid-template-rows: 1fr auto;
        grid-template-areas: "title price"
                             "size actions";
        column-gap: 20px;
        padding: 20px 0px;

        .size-wrp {
          display: flex;
          align-items: flex-end;
        }
        .price-wrp {
          text-align: right;
        }
      }
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }
}
.order-confirm-items {
  padding: 20px;
  background-color: $white;

  .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  .order-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  .items-wrp {
    margin-bottom: 26px;
    padding: 32px 0px;
    border-top: 1px solid rgba(34, 34, 34, 0.12);
    border-bottom: 1px solid rgba(34, 34, 34, 0.12);
  }
  .summary-wrp {
    margin-bottom: 20px;

    .list-unstyled > li {
      margin-bottom: 12px;
    }
  }
  @include media-breakpoint-up(lg) {
    padding: 31px 48px;

    .items-wrp {
      margin-bottom: 38px;

      .cart-item-element {
        grid-template-columns: 96px auto;
        column-gap: 40px;

        .text-wrp {
          grid-template-areas: "title size price";
          grid-template-columns: 1fr 20% 20%;
          align-items: center;
        }
        &:not(:last-child) {
          margin-bottom: 14px;
        }
      }
    }
    .summary-wrp {
      display: flex;
      justify-content: flex-end;

      > ul {
        width: 306px;
      }
    }
  }
}

#cart_vouchers {
  margin-bottom: 20px;

  .accordion-header {
    border-top: 1px solid $color-dark;

    .accordion-button {
      padding: 16px 0px;
      font-size: 14px;
      line-height: 16px;

      &:after {
        height: 16px;
      }
      &:not(.collapsed) {
        box-shadow: none;
      }
    }
  }
}

#cartLoyaltyAccordion {
  border-top: 1px solid $color-dark;

  .accordion-item {
    .accordion-header {
      .accordion-button {
        &:not(.collapsed) {
          font-weight: 500;
          box-shadow: none;
        }
      }
    }
    .accordion-body {
      padding-top: 0px;
      padding-bottom: 55px;
    }
  }
}

#credit-form {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 12px;
}
#paymentMethods {
  .payment-option {
    .accordion-header {
      .accordion-button {
        min-height: 54px;

        .indicator {
          position: relative;
          display: block;
          margin-right: 10px;
          width: 25px;
          height: 25px;
          background-color: #eeeeee;
          border-radius: 50%;
        }
        &:not(.collapsed) {
          color: $primary;

          .indicator {
            background-color: $primary;

            &:after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              display: block;
              height: 14px;
              width: 8px;
              border-right: 2px solid #ffffff;
              border-bottom: 2px solid #ffffff;
              transform: rotate(45deg) translate(-50%, -50%);
              transform-origin: top;
            }
          }
        }
      }
    }
    .accordion-collapse {
      .accordion-body {
        .payment_list {
          .payment_type {
            transition: all .3s;

            &.active {
              border-color: $primary !important;
              box-shadow: 0px 0px 0px 3px $primary;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
    &.no-options {
      .accordion-header {
        .accordion-button {
          &:not(.collapsed) {
            box-shadow: none !important;
          }
          &:after {
            display: none;
          }
        }
      }
      .accordion-collapse {
        .accordion-body {
          padding: 0px !important;
        }
      }
    }
  }
}

#delivery_options_form {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 16px;

  #delivery_type_options {
    margin-bottom: 22px;

    .delivery-option-wrp {
      .form-check {
        position: relative;
        margin-bottom: 0px;
        padding: 11px 20px 16px 49px;
        border-bottom: 2px solid #FBF9F6;

        label {
          display: grid;
          grid-template-areas: "title"
                               "description"
                               "price";

          .title {
            grid-area: title;
            padding-top: 4px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: $color-dark;
          }
          .description {
            grid-area: description;
            margin-top: 10px;
            margin-bottom: 0px;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #8D8D8D;
          }
          .dream_room_message{
            margin-top: 10px;
            margin-bottom: 0px;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
          }
          .price {
            grid-area: price;
            margin-top: 10px;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: $color-dark;
          }
        }
        .form-check-input:checked[type=radio] + label {
          .title {
            font-weight: 500;
          }
          &:before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            display: block;
            width: 2px;
            height: 100%;
            background-color: $color-dark;
          }
        }
      }
    }
  }
  #delivery_info {
    padding: 0px 20px;
  }

  @include media-breakpoint-up(md) {
    margin-left: 0px;
    margin-right: 0px;
  }
  @include media-breakpoint-up(lg) {
    .block-title {
      padding: 32px 48px 15px 48px;
    }
    #delivery_type_options {
      padding-bottom: 32px;
      border-bottom: 2px solid #FBF9F6;

      .delivery-option-wrp {
        .form-check {
          padding: 11px 48px 11px 76px;
          border-bottom: none;

          label {
            grid-template-areas: "title price"
                               "description price";

            .description {
              margin-top: 5px;
            }
            .price {
              margin-top: 5px;
              font-size: 16px;
              line-height: 20px;
              text-align: right;
            }
          }
          .form-check-input:checked[type=radio] + label {
            &:before {
              display: none;
            }
          }
        }
      }
    }
    #delivery_info {
      padding: 16px 48px;

      .row {
        margin-left: -10px;
        margin-right: -10px;

        & > * {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}
.next-step {
  .block-title {
    color: #8D8D8D;
  }
}
.prev-step {
  .title {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 20px;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 13px;

    .block-title {
      padding: 0px;
      border-bottom: none;
    }
    .edit-delivery {
      font-size: 14px;
      line-height: 16px;

      i {
        margin-right: 3px;
      }
      span {
        text-decoration: underline;
      }
    }
  }
  .delivery-info {
    padding: 0px 20px 28px 20px;

    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .title {
      padding-top: 32px;
      padding-bottom: 16px;

      .block-title {}
    }
    .delivery-info {
      padding: 0px 20px 40px 20px;
    }
  }
}

#order_payment_block {
  #paymentMethods {
    .accordion-item {
      border-bottom: none;

      .accordion-header {
        .accordion-button {
          justify-content: flex-start;
          min-height: initial;
          padding: 10px 0px;

          .indicator {
            margin-right: 14px;
            width: 20px;
            height: 20px;
            background-color: transparent;
            border: 1px solid $color-dark;
          }
          &:not(.collapsed) {
            font-weight: 500;
            color: $color-dark;

            .indicator {
              background-color: $color-dark;

              &:after {
                width: 8px;
                height: 8px;
                background-color: $white;
                border-radius: 50%;
                border: none;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }
    }
  }
}