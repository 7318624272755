@import "components/header";

body {
  background-color: $primary;
}
.no-scroll {
  overflow: hidden;
}
@include media-breakpoint-down(lg) {
  .no-scroll-mob {
    overflow: hidden;
  }
}

.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  padding-right: 20px;
  padding-left: 20px;
}

.footer-mob-block {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $color-dark;

  h3 {
    margin-bottom: 3px;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
  }
  .content-wrp {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
  }
  &.contacts-wrp {
    .content-wrp {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      a {
        margin-right: 24px;
        text-decoration: underline;
      }
    }
  }
}

#customers_authorize {
  max-width: 458px;

  .page_title {

  }
  main & {
    margin-top: 32px;
    max-width: 642px;
    padding: 20px;
    background-color: $white;
  }

  @include media-breakpoint-up(md) {
    main & {
      padding: 48px 64px;
    }
  }
}

footer.footer {
  padding-top: 8px;
  padding-bottom: 48px;

  .inner-wrp {
    display: grid;
  }
  #pages_footer_menu {
    .widget-list {
      padding-bottom: 20px;

      .widget-list-item {
        .widget-list-link {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    @include media-breakpoint-up(md) {
      h3.title {
        margin-bottom: 18px;
      }
    }
  }
  .footer-description-wrp {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 8px;
    font-size: 14px;
    line-height: 24px;

    p {
      margin-bottom: 24px;
    }
    .customer-service-wrp {
      margin-bottom: 14px;

      .content-wrp {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        a {
          display: inline-block;
          margin-right: 24px;
          margin-bottom: 10px;
        }
      }
    }
    &:after {
      content: "";
      position: absolute;
      left: 0px;
      bottom: 0px;
      display: block;
      width: 32px;
      border-bottom: 1px solid $color-dark;
    }
  }
  #footerSocial {
    margin-bottom: 32px;

    a {
      display: inline-block;
      padding: 5px;
    }
  }
  #footerPayments {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    img {
      margin-bottom: 10px;
      margin-right: 16px;
    }
  }
  #copyright {
    small {
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }
  &.footer-checkout {
    .container {
      max-width: 1130px;
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 48px;

    .inner-wrp {
      grid-template-columns: auto 40.3%;
      column-gap: 11%;
      margin-bottom: 36px;
    }
  }
}

.layout-wt-side-content-wrp {
  background-color: $white;

  .inner-wrp {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }

  #pages_side_menu {
    margin-bottom: 32px;
    background-color: $primary;

    .list-collapse-mobile {
      border-bottom: none;
    }

    h3 {
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 18px;
      padding-right: 40px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      font-family: $font-family-base;
      border-left: 2px solid $color-dark;
      background-color: $primary;

      &:after {
        right: 20px;
      }
    }
    ul {
      padding: 0px 20px;
      list-style: none;

      li {
        padding: 4px 0px;

        a {
          display: block;
          padding: 10px 0px;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .inner-wrp {
      grid-auto-flow: column;
      grid-template-columns: 250px auto;

      > aside {
        margin-left: 20px;
      }
    }
    #pages_side_menu {
      background-color: transparent;

      h3 {
        display: none;
      }
      ul {
        padding: 0px;

        li {
          padding: 8px 0px;

          a {
            padding: 2px 0px 2px 10px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            &.active {
              border-left: 2px solid $color-dark;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .inner-wrp {
      grid-template-columns: 1fr 680px 1fr;

      > aside {
        margin-right: 20px;
      }
    }

  }
}
.content_layout_checkout {
  #main-header {
    .navbar:not(.navbar-stuck-menu) {
      padding: 8px 0px;

      .container {
        max-width: 1130px;
        grid-template-columns: auto 1fr;
        column-gap: 20px;

        .secure-block {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;

          span {
            margin-left: 4px;
          }
        }
        .navbar-brand {
          width: 123px;
        }
        .contact-block {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          text-align: right;

          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
  #content_wrapper {
    padding-top: 24px;
  }

  @include media-breakpoint-up(md) {
    #main-header {
      .navbar:not(.navbar-stuck-menu) {
        padding: 14px 0px;

        .container {
          grid-template-columns: 1fr auto 1fr;

          .navbar-brand {
            width: 206px;
          }
        }
      }
    }
    #content_wrapper {
      padding-top: 56px;
    }
  }
}