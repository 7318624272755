#orders_detailed .custom-modal {
  .modal-content {
    border-radius: 0;

    .modal-header {
      padding: 36px 40px;
      h2 {
        font-size:28px;
        line-height: 28px;
      }
      .close-btn {
        position:absolute;
        top:12px;
        right:16px;
      }
    }

    .modal-body {
      padding: 0px;
      .cancel-info {
        background:#FBF9F6;
        display:flex;
        padding: 28px 40px;
        .icon {
          padding-right:16px;
        }
        h3 {
          font-size: 20px;
          line-height: 24px;
          font-weight: normal;
        }
        p {
          font-size:14px;
          line-height:20px;
          margin: 0px;
        }
      }
      .method-select {
        padding: 36px 40px;

        h4 {
          font-size:16px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 1px;
          text-transform: uppercase;
          margin-bottom: 20px;
        }
        .form-check {
          margin-bottom: 12px;
        }
        .bank-fields {
          margin-top: 4px;
          margin-left: 35px;
          display: none;
          .form-control {
            margin-bottom: 8px;
          }
        }
      }
    }
    .modal-footer {
      padding: 0px 40px 36px;

      button {
        max-width: 100%
      }
    }
  }
}