.btn {
  padding: 12px 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  border-radius: 0px;
  transition: all .3s;

  &:hover, &:active, &:focus, &.active, &.show {
    opacity: 0.95;
  }
  &:active, &.active {
    opacity: 1;
  }
  &.btn-lg {
    padding: 12px 28px;
  }
}
.btn-primary {
  color: $white;
  background-color: $color-dark;
  border-color: $color-dark;
  text-transform: uppercase;

  &:hover, &:active, &:focus, &.active, &.show {
    background-color: $color-dark;
    border-color: $color-dark;
  }
  &.arrow-right {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuODEyNSAwLjIxODc1TDYuMTg3NSAwLjgxMjVDNi4wMzEyNSAwLjk2ODc1IDYuMDMxMjUgMS4yMTg3NSA2LjE4NzUgMS4zNDM3NUwxMS4wMzEyIDYuMTg3NUgwLjM3NUMwLjE1NjI1IDYuMTg3NSAwIDYuMzc1IDAgNi41NjI1VjcuNDM3NUMwIDcuNjU2MjUgMC4xNTYyNSA3LjgxMjUgMC4zNzUgNy44MTI1SDExLjAzMTJMNi4xODc1IDEyLjY4NzVDNi4wMzEyNSAxMi44MTI1IDYuMDMxMjUgMTMuMDYyNSA2LjE4NzUgMTMuMjE4OEw2LjgxMjUgMTMuODEyNUM2LjkzNzUgMTMuOTY4OCA3LjE4NzUgMTMuOTY4OCA3LjM0Mzc1IDEzLjgxMjVMMTMuODc1IDcuMjgxMjVDMTQuMDMxMiA3LjEyNSAxNC4wMzEyIDYuOTA2MjUgMTMuODc1IDYuNzVMNy4zNDM3NSAwLjIxODc1QzcuMTg3NSAwLjA2MjUgNi45Mzc1IDAuMDYyNSA2LjgxMjUgMC4yMTg3NVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
  }
  &:disabled, &.disabled {
    color: $white;
    background-color: $color-dark;
    border-color: $color-dark;
    opacity: 0.2;
  }
}
.btn-outline-primary {
  color: $color-dark;
  border-color: $color-dark;

  &:hover, &:active, &:focus, &.active, &.show {
    color: $white;
    background-color: $color-dark;
    border-color: $color-dark;
  }
}

.btn-secondary {
  color: $color-dark;
  background-color: $white;
  border-color: $color-dark;
  text-transform: uppercase;

  &:hover, &:active, &:focus, &.active, &.show {
    color: $white;
    background-color: $color-dark;
    border-color: $color-dark;
  }
}

.btn-creamy {
  color: $color-dark;
  background-color: #E9DFD5;
  border-color: #E9DFD5;
  text-transform: uppercase;

  &:hover, &:active, &:focus, &.active, &.show {
    color: $color-dark;
    background-color: #E9DFD5;
    border-color: #E9DFD5;
  }
}