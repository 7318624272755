#feedback_page {
  .feedback-group {
    .answers {
      .feedback-table {
        display: grid;
        grid-auto-flow: row;
        row-gap: 10px;

        .answer {
          label {
            display: block;
            padding: 8px 15px;
            color: $color-dark;
            background-color: $primary;
            border: 1px solid #7e7d7d;
            cursor: pointer;
            transition: all .3s;
          }
          input {
            display: none;

            &:checked + label {
              color: $white;
              background-color: $color-dark;
            }
          }
        }
      }
    }
  }
  #product_feedback {
    .form-select {
      &:focus {
        border: 1px solid #222222;
      }
      &.is-invalid {
        border-color: #f34770;
      }
    }
    .input-group {
      input {
        border-right: 0px;
      }
      .input-group-text {
        border: 1px solid #222222;
        border-radius: 0px;
      }
      &.is-invalid {
        input {
          border-color: #f34770;
        }
        .input-group-text {
          border-color: #f34770;
        }
      }
    }
    .group_title {}
    .add_review {
      .product-feedback-wrp {
        display: grid;
        grid-template-areas: "product"
                             "feedback";

        .product-element-wrp {
          grid-area: product;
          justify-self: center;
          align-self: flex-start;
          display: inline-block;

          .product-title {
            position: absolute;
            bottom: 10px;
            left: 0px;
            padding: 5px 15px;
            max-width: 70%;
            background-color: rgba(255, 255, 255, .7);

            .brand-title {
              display: block;
              color: $color-dark;
            }
          }
        }
        .feedback-wrp {
          grid-area: feedback;

          .common-rating {
            .rating-buttons {
              display: flex;

              a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                color: $color-dark;
                background-color: $primary;
                border: 1px solid #7e7d7d;
                transition: all .3s;

                &.active {
                  color: $white;
                  background-color: $color-dark;
                }
                &:not(:last-child) {
                  margin-right: 5px;
                }
              }
            }
          }
        }
        .review-wrp {
          grid-area: review;
        }
        .image-uploader {
          display: block;

          .uploaded {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            column-gap: 8px;
            row-gap: 8px;

            .uploaded-image, .upload-text {
              margin: 0px;
              width: 100%;
              height: auto;
              aspect-ratio: 1/1;
            }
            .upload-text {
              i {
                margin-bottom: 14px;
              }
            }
          }
        }
        .descr {
          font-size: 0.875rem;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .feedback-group {
      .answers {
        .feedback-table {
          //grid-auto-flow: column;
          //column-gap: 10px;

          .answer {
            input {}
          }
        }
      }
    }
    #product_feedback {
      .group_title {}
      .add_review {
        .product-feedback-wrp {
          grid-template-areas: "product feedback";
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: 30px;

          .image-uploader {
            .uploaded {
              grid-template-columns: repeat(4, minmax(0, 1fr));
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    #product_feedback {
      .add_review {
        .product-feedback-wrp {
          .image-uploader {
            .uploaded {
              grid-template-columns: repeat(5, minmax(0, 1fr));
            }
          }
        }
      }
    }
  }
}