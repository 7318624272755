.collections_index_listing {
  margin-top: 30px;
  overflow: hidden;

  .inner-wrp {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;

    .content-wrp {
      margin-bottom: 32px;
      text-align: center;

      h2 {
        margin-bottom: 18px;
      }
      p {
        font-size: 14px;
        line-height: 24px;

        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }
    .products-wrp {
      overflow: hidden;
      margin: {
        top: 0px;
        left: -20px;
        right: -20px;
        bottom: 32px;
      }
      padding: 0px 20px;

      .tns-ovh {
        overflow: initial;

        .tns-carousel-inner-new {
          overflow: initial;

          .product-card {}
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    > .container {
      padding: 0px;
    }
    .inner-wrp {
      grid-template-columns: 306px auto;
      column-gap: 30px;

      .content-wrp {
        margin-bottom: 0px;
        padding-left: 20px;
        text-align: left;

        h2 {
          margin-bottom: 19px;
        }
        .btn-wrp {
          margin-top: 32px;

          .btn {
            min-width: 144px;
          }
        }
      }
      .products-wrp {
        position: relative;
        margin: 0px;
        padding: 0px 20px;

        .tns-carousel-controls {
          button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &[data-controls="prev"] {
              left: 8px;
            }
            &[data-controls="next"] {
              right: 8px;
            }
          }
        }
        .tns-carousel {
          overflow: hidden;

          .tns-ovh {
            .tns-carousel-inner-new {
              .product-card {
                max-width: initial;
                width: auto;
              }
            }
          }
          // kol neuzkrauta
          .tns-carousel-inner-new:not(.tns-carousel) {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            column-gap: 19px;

            > div:not(.tns-item) {
              &:nth-child(n + 5) {
                display: none;
              }
            }

            .layout-wt-side-content-wrp & {
              grid-template-columns: repeat(2, minmax(0, 1fr));

              > div:not(.tns-item) {
                &:nth-child(n + 3) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}