@use "sass:map";

.linepromos {
  .topbar-text {
    padding: 2px 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    font-family: $font-family-basier-squere;

    p {
      margin: 0px;
    }
  }

  @include media-breakpoint-up(lg) {
    .topbar-text {
      padding: 4px 0px !important;
      font-size: 12px !important;
      line-height: 24px;
    }
  }
}