.image-uploader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  input[type="file"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .upload-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80px;
    height: 80px;
    font-size: 10px;
    text-transform: uppercase;
    border: 1px solid #222222;
    cursor: pointer;

    i {
      display: block;
      font-size: 20px;
      margin-bottom: 6px;
    }
    span { display: block; }
  }
  .uploaded {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    line-height: 0;

    .uploaded-image {
      position: relative;
      display: inline-block;
      margin-right: 8px;
      padding-bottom: 80px;
      width: 80px;
      height: 0;
      background: #f3f3f3;
      cursor: default;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .delete-image {
        position: absolute;
        top: -2px;
        right: -2px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background-color: #222222;
        -webkit-appearance: none;
        cursor: pointer;
        border: none;

        i {
          font-size: 12px;
          line-height: 19px;
          color: #fff;
        }
      }
    }
  }
  &.has-files {
    //.upload-text { display: none; }
  }
  &.drag-over { background-color: #f3f3f3; }
}