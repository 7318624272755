ul.filters-list {
  li.with-icon {
    list-style: none;
    position: relative;

    i {
      position:absolute;
      margin-left: -20px;
    }
  }
}