#products_detailed {
  overflow: hidden;

  .product_block {
    .top-layout {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: auto auto minmax(0, 1fr);

      .product-gallery-block {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        display: grid;
        margin-bottom: 20px;

        .product-gallery-thumblist {
          display: none;
          margin-top: -1px;

          .product-gallery-thumblist-item {
            display: block;
            position: relative;
            margin: 1px;
            text-decoration: none !important;
            border: 1px solid $primary;

            &:hover {
              //border-color: $product-gallery-thumb-hover-border-color;
            }
            &.active {
              box-shadow: 0px 0px 0px 1px rgba(0,0,0,1);
              border: 1px solid $white;
              //border-color: $product-gallery-thumb-active-border-color;
            }
          }
          .tns-carousel {
            .tns-outer {
              height: 100%;
              overflow: hidden;

              .tns-ovh {
                height: 100% !important;
              }
              .tns-controls {
                button {
                  left: 50%;
                  right: initial;
                  margin: 0px;
                  width: 28px;
                  height: 20px;
                  line-height: 20px;
                  background-color: $primary;
                  border: none;
                  border-radius: 0px;
                  transform: translateX(-50%);

                  &[data-controls="prev"] {
                    top: 0px;
                  }
                  &[data-controls="next"] {
                    top: initial;
                    bottom: 0px;
                  }
                }
              }
            }
          }
        }
        .product-gallery-preview {
          position: relative;
          width: 100%;

          .add_to_wishlist2 {
            position: absolute;
            top: 0px;
            right: 4px;
            background-color: transparent;
            z-index: 1;

            .counter {
              margin-top: -10px;
            }
          }
          .product-gallery-preview-item {
            display: flex;
            justify-content: center;

            > img {
              display: block;
              width: 100%;
            }
            &.active {}
            &:hover { cursor: zoom-in; }
          }
        }
      }
      .deal-wrp {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        align-self: start;
        display: grid;
        grid-auto-flow: row;
        row-gap: 16px;
        padding: 24px 32px;

        h4 {
          margin-bottom: 0px;
          padding: 0px 7px;
          font-size: 32px;
          line-height: 40px;
          letter-spacing: -3.3px;
        }
        p {
          margin-bottom: 7px;
          font-size: 14px;
          line-height: 24px;

          a {
            text-decoration: underline;
          }
        }
      }
      .accessories-wrp {
        grid-column: 1 / 2;
        grid-row: 4 / 5;
        margin-top: 30px;

        h2 {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          font-family: $font-family-base;
        }

        .slider-wrp {
          .tns-carousel-controls {
            button {
              position: absolute;
              top: 50%;
              width: 28px !important;
              height: 28px !important;
              font-size: 12px;
              line-height: 28px;
              transform: translateY(-50%);

              &[data-controls="next"] {
                right: 0px;
              }
            }
          }
          .accesory-element {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              margin-bottom: 12px;
              padding: 0px 12px;
            }
            .text-wrp {
              display: flex;
              flex-direction: column;
              text-align: center;

              .title {
                margin-bottom: 4px;
                font-weight: 500;
                font-size: 8px;
                line-height: 12px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
              }
              .subtitle {
                font-size: 10px;
                line-height: 14px;
              }
            }
          }
        }
      }
      #products_add2cart {
        grid-column: 1 / 2;
        grid-row: 2 / 3;

        #size-chart {
          .nav-link {
            font-size: 15px;
            line-height: 130%;
            text-transform: initial;
            text-decoration: none;
          }
        }
        .product-details {
          .product-stars-wrp {
            margin-bottom: 12px;

            a {
              .star-rating {
                font-size: 0.72rem;

                i {}
                span {
                  font-weight: 500;
                  font-size: 10px;
                  line-height: 16px;
                  letter-spacing: 0.1em;
                  text-decoration-line: underline;
                  text-transform: uppercase;
                }
              }
              > span {
                font-weight: 500;
                font-size: 10px;
                line-height: 16px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                text-decoration: underline;
              }
            }
          }
          .product-title {
            font-weight: 400;
            font-size: 32px;
            line-height: 40px;
          }
          .brand-title {
            margin-bottom: 18px;
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
          }
          .price-wrp {
            display: flex;
            justify-content: space-between;
            margin-bottom: 41px;
            font-family: $font-family-base;

            .main-price {
              font-weight: 400;
              font-size: 20px;
              line-height: 24px;
            }
            .initial-price {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              text-align: right;
              color: #8D8D8D;
            }
          }
          .selectors-wrp {
            label, .size-selector a {
              font-weight: 500;
              font-size: 10px;
              line-height: 16px;
              letter-spacing: 0.1em;
              text-transform: uppercase;
            }

            .form-select {
              border-color: #fdfcfb;
            }
            .size-selector {
              a {
                text-decoration: underline;
              }
            }
            .duration-selector {
              > label {
                margin-bottom: 11px;
              }
              ul {
                
                  display: grid;
                  grid-template-columns: repeat(4, 1fr);
                  &.count3{
                    grid-template-columns: repeat(3, 1fr);
                  }
                  &.count1{
                    grid-template-columns: repeat(1, 1fr);
                  }
                  &.count7{
                    grid-template-columns: repeat(12, 1fr);
                    li{
                      grid-column: span 3;
                    }
                    li:nth-child(n+5):nth-child(-n+7){
                      grid-column: span 4;
                    }
                  }
                  //&.count8{
                  //  &:nth-child(n+5):nth-child(-n+7){
                  //    grid-column: span 1;
                  //  }
                  //}


                  grid-auto-flow: row dense;
                  column-gap: 1px;
                  margin: 0;
                  padding-left: 0;
                  list-style: none;
                  row-gap: 1px;
                  li{


                    &:nth-last-child(1):nth-child(5){
                      grid-column: 1 / -1;
                    }
                    &:nth-last-child(2):nth-child(5),
                    &:nth-last-child(1):nth-child(6){
                      grid-column: span 2;
                    }

                    &:first-child:nth-last-child(2),
                    &:nth-child(2):nth-last-child(1) {
                      grid-column: span 2;
                    }



                  }

                li {
                  input {
                    display: none;

                    &:checked + label {
                      border: 1px solid $color-dark;
                    }
                  }
                  label {
                    display: flex;
                    align-items: center;
                    padding: 14px 14px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: center;
                    text-transform: initial;
                    letter-spacing: 0px;
                    background-color: $white;
                    transition: all .3s;
                    border: 1px solid $white;

                    &:hover {
                      cursor: pointer;
                    }
                  }

                }


              }
            }
            .rent-date-selector {
              margin-bottom: 42px;

              > label {
                margin-bottom: 11px;
              }
              .rent-dates-placeholder {
                position: relative;
                background-color: $white;
                border-color: $white;
                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgd2lkdGg9IjE0LjI1IgogICBoZWlnaHQ9IjE1LjI1IgogICB2aWV3Qm94PSIwIDAgMTQuMjUgMTUuMjUiCiAgIGZpbGw9Im5vbmUiCiAgIHZlcnNpb249IjEuMSIKICAgaWQ9InN2ZzEwIgogICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxkZWZzCiAgICAgaWQ9ImRlZnMxNCIgLz4KICA8cGF0aAogICAgIGlkPSJwYXRoMiIKICAgICBzdHlsZT0iY29sb3I6IzAwMDAwMDtmaWxsOiMyMjIyMjI7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kOy1pbmtzY2FwZS1zdHJva2U6bm9uZSIKICAgICBkPSJtIDQuMjM0Mzc1LDAgYSAwLjYyNSwwLjYyNSAwIDAgMCAtMC42MjUsMC42MjUgViAxLjQwMDM5MDYgSCAyLjA3MDMxMjUgQyAwLjk0NTE4MTEyLDEuNDAwMzkwNiAwLDIuMzAyMTI3MyAwLDMuNDIzODI4MSBWIDYuMjI0NjA5NCAxMy4yMjQ2MDkgQyAwLDE0LjM0NjMwNSAwLjk0NTE5MTU2LDE1LjI1IDIuMDcwMzEyNSwxNS4yNSBIIDEyLjE3OTY4OCBDIDEzLjMwNDc4NCwxNS4yNSAxNC4yNSwxNC4zNDYzMDUgMTQuMjUsMTMuMjI0NjA5IFYgNi4yMjQ2MDk0IDMuNDIzODI4MSBjIDAsLTEuMTIxNzAwOCAtMC45NDUyMDYsLTIuMDIzNDM3NSAtMi4wNzAzMTIsLTIuMDIzNDM3NSBIIDEwLjY0MDYyNSBWIDAuNjI1IEEgMC42MjUsMC42MjUgMCAwIDAgMTAuMDE1NjI1LDAgMC42MjUsMC42MjUgMCAwIDAgOS4zOTA2MjUsMC42MjUgViAxLjQwMDM5MDYgSCA0Ljg1OTM3NSBWIDAuNjI1IEEgMC42MjUsMC42MjUgMCAwIDAgNC4yMzQzNzUsMCBaIE0gMi4wNzAzMTI1LDIuNjUwMzkwNiBIIDMuNjA5Mzc1IHYgMC43NzUzOTA2IGEgMC42MjUsMC42MjUgMCAwIDAgMC42MjUsMC42MjUgMC42MjUsMC42MjUgMCAwIDAgMC42MjUsLTAuNjI1IFYgMi42NTAzOTA2IGggNC41MzEyNSB2IDAuNzc1MzkwNiBhIDAuNjI1LDAuNjI1IDAgMCAwIDAuNjI1LDAuNjI1IDAuNjI1LDAuNjI1IDAgMCAwIDAuNjI1LC0wLjYyNSBWIDIuNjUwMzkwNiBoIDEuNTM5MDYzIEMgMTIuNjQ5OTc2LDIuNjUwMzkwNiAxMywyLjk5OTExMiAxMywzLjQyMzgyODEgViA1LjU5OTYwOTQgSCAxLjI1IFYgMy40MjM4MjgxIEMgMS4yNSwyLjk5OTExMiAxLjU5OTk2NzEsMi42NTAzOTA2IDIuMDcwMzEyNSwyLjY1MDM5MDYgWiBNIDEuMjUsNi44NDk2MDk0IEggMTMgViAxMy4yMjQ2MDkgQyAxMywxMy42NDkzMTEgMTIuNjQ5OTg2LDE0IDEyLjE3OTY4OCwxNCBIIDIuMDcwMzEyNSBDIDEuNTk5OTU2NywxNCAxLjI1LDEzLjY0OTMxMSAxLjI1LDEzLjIyNDYwOSBaIiAvPgo8L3N2Zz4K);
                background-position: calc(100% - 16px) center;
                background-repeat: no-repeat;
              }
            }
            .fake-date-input {
              display: block;
              padding: 12px 34px 12px 16px;
              width: 100%;
              height: 40px;
              font-size: 14px;
              line-height: 16px;
              color: #8D8D8D;
              text-align: left;
              background-color: $white;
              background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgd2lkdGg9IjE0LjI1IgogICBoZWlnaHQ9IjE1LjI1IgogICB2aWV3Qm94PSIwIDAgMTQuMjUgMTUuMjUiCiAgIGZpbGw9Im5vbmUiCiAgIHZlcnNpb249IjEuMSIKICAgaWQ9InN2ZzEwIgogICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxkZWZzCiAgICAgaWQ9ImRlZnMxNCIgLz4KICA8cGF0aAogICAgIGlkPSJwYXRoMiIKICAgICBzdHlsZT0iY29sb3I6IzAwMDAwMDtmaWxsOiMyMjIyMjI7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kOy1pbmtzY2FwZS1zdHJva2U6bm9uZSIKICAgICBkPSJtIDQuMjM0Mzc1LDAgYSAwLjYyNSwwLjYyNSAwIDAgMCAtMC42MjUsMC42MjUgViAxLjQwMDM5MDYgSCAyLjA3MDMxMjUgQyAwLjk0NTE4MTEyLDEuNDAwMzkwNiAwLDIuMzAyMTI3MyAwLDMuNDIzODI4MSBWIDYuMjI0NjA5NCAxMy4yMjQ2MDkgQyAwLDE0LjM0NjMwNSAwLjk0NTE5MTU2LDE1LjI1IDIuMDcwMzEyNSwxNS4yNSBIIDEyLjE3OTY4OCBDIDEzLjMwNDc4NCwxNS4yNSAxNC4yNSwxNC4zNDYzMDUgMTQuMjUsMTMuMjI0NjA5IFYgNi4yMjQ2MDk0IDMuNDIzODI4MSBjIDAsLTEuMTIxNzAwOCAtMC45NDUyMDYsLTIuMDIzNDM3NSAtMi4wNzAzMTIsLTIuMDIzNDM3NSBIIDEwLjY0MDYyNSBWIDAuNjI1IEEgMC42MjUsMC42MjUgMCAwIDAgMTAuMDE1NjI1LDAgMC42MjUsMC42MjUgMCAwIDAgOS4zOTA2MjUsMC42MjUgViAxLjQwMDM5MDYgSCA0Ljg1OTM3NSBWIDAuNjI1IEEgMC42MjUsMC42MjUgMCAwIDAgNC4yMzQzNzUsMCBaIE0gMi4wNzAzMTI1LDIuNjUwMzkwNiBIIDMuNjA5Mzc1IHYgMC43NzUzOTA2IGEgMC42MjUsMC42MjUgMCAwIDAgMC42MjUsMC42MjUgMC42MjUsMC42MjUgMCAwIDAgMC42MjUsLTAuNjI1IFYgMi42NTAzOTA2IGggNC41MzEyNSB2IDAuNzc1MzkwNiBhIDAuNjI1LDAuNjI1IDAgMCAwIDAuNjI1LDAuNjI1IDAuNjI1LDAuNjI1IDAgMCAwIDAuNjI1LC0wLjYyNSBWIDIuNjUwMzkwNiBoIDEuNTM5MDYzIEMgMTIuNjQ5OTc2LDIuNjUwMzkwNiAxMywyLjk5OTExMiAxMywzLjQyMzgyODEgViA1LjU5OTYwOTQgSCAxLjI1IFYgMy40MjM4MjgxIEMgMS4yNSwyLjk5OTExMiAxLjU5OTk2NzEsMi42NTAzOTA2IDIuMDcwMzEyNSwyLjY1MDM5MDYgWiBNIDEuMjUsNi44NDk2MDk0IEggMTMgViAxMy4yMjQ2MDkgQyAxMywxMy42NDkzMTEgMTIuNjQ5OTg2LDE0IDEyLjE3OTY4OCwxNCBIIDIuMDcwMzEyNSBDIDEuNTk5OTU2NywxNCAxLjI1LDEzLjY0OTMxMSAxLjI1LDEzLjIyNDYwOSBaIiAvPgo8L3N2Zz4K);
              background-position: calc(100% - 16px) center;
              background-repeat: no-repeat;
              border: none;
            }

            .add-btn-wrp {
              grid-template-columns: minmax(0, 1fr);

              &.wt-nagotiations {
                column-gap: 8px;
                row-gap: 8px;
              }
            }
            #add2cart_button {
              position: relative;

              i {
                position: absolute;
                top: calc(50% + 3px);
                left: 14px;
                font-size: 15px;
                transform: translateY(-50%);
              }
            }

            .buy-link {
              margin-bottom: 12px;
              font-weight: 600;
              font-size: 12px;
              line-height: 24px;
              letter-spacing: 0.03em;
              text-transform: uppercase;

              a {
                text-decoration: underline;
              }
            }
            .item-location {
              font-weight: 500;
              font-size: 12px;
              line-height: 24px;
              letter-spacing: 0.03em;
              text-transform: uppercase;

              span {

              }
              i {
                font-size: 15px;
              }
            }
          }
          #productPanels {
            margin-bottom: 64px;
            border-top: 1px solid $color-dark;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .product_block {
      .top-layout {
        .product-gallery-block {
          margin-left: -20px;
          margin-right: -20px;
        }
        .accessories-wrp {
          .slider-wrp {
            .tns-carousel {
              .tns-ovh {
                overflow: visible;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .product_block {
      padding-top: 32px;

      .top-layout {
        grid-template-columns: 350px minmax(0, 1fr);
        column-gap: 30px;

        .product-gallery-block {
          grid-column: 1 / 2;
          grid-row: 1 / 3;
          margin-bottom: 31px;

          .product-gallery-preview {
            .add_to_wishlist2 {
              top: 6px;
              right: 25px;
            }
          }
        }
        .deal-wrp {
          grid-column: 1 / 2;
          grid-row: 3 / 4;
          grid-auto-flow: column;
          column-gap: 20px;

          h4 {
            order: 2;
            display: flex;
            align-items: center;
            padding: 0px;
          }
          p {
            order: 1;
            margin-bottom: 0px;
          }
        }
        .accessories-wrp {
          grid-column: 2 / 3;
          grid-row: 4 / 6;

          h2 {
            margin-bottom: 24px;
          }
        }
        #products_add2cart {
          grid-column: 2 / 3;
          grid-row: 1 / 4;

          .product-details {
            .product-stars-wrp {
              margin-bottom: 15px;
              line-height: 14px;
            }
            .product-title {
              font-size: 40px;
              line-height: 48px;
            }
            .brand-title {
              margin-bottom: 22px;
            }
            .price-wrp {
              justify-content: flex-start;

              .initial-price {
                position: relative;
                margin-left: 24px;
                padding-left: 22px;
                font-size: 16px;
                line-height: 24px;

                &:before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 0px;
                  display: block;
                  width: 1px;
                  height: 18px;
                  background-color: #8D8D8D;
                  transform: translateY(-50%);
                }
              }
            }
            .selectors-wrp {
              margin-bottom: 15px !important;

              .buy-link {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .product_block {
      .top-layout {
        grid-template-columns: 540px minmax(0, 1fr);
        grid-template-rows: auto auto minmax(0, 1fr);
        column-gap: 50px;

        .product-gallery-block {
          grid-template-columns: 82px auto;
          column-gap: 23px;

          .product-gallery-thumblist {
            display: block;
          }
        }
        .deal-wrp {
          justify-self: end;
          width: calc(100% - 105px);

          h4 {
            font-size: 40px;
            line-height: 48px;
          }
        }
        #products_add2cart {
          .product-details {
            .selectors-wrp {
              .duration-selector {
                ul {
                  li {
                    label {
                      padding: 14px 24px;
                      font-size: 14px;
                      line-height: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .product_block {
      .top-layout {
        grid-template-columns: 653px minmax(0, 1fr);
        grid-template-rows: auto auto minmax(0, 1fr);
        column-gap: 84px;

        #products_add2cart {
          .product-details {
            .selectors-wrp {
              .add-btn-wrp {
                &.wt-nagotiations {
                  grid-template-columns: minmax(0, 6fr) minmax(0, 4fr);
                }
              }
            }
          }
        }
      }
    }
  }
  .delivery_date{
    margin-bottom: 30px;
    font-size: 12px;
    margin-top: -10px;
  }
}

#similarProducts, #relatedProducts, .related-products-wrp {
  .tns-carousel-controls {
    button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &[data-controls="prev"] {
        left: -10px;
      }
      &[data-controls="next"] {
        right: -10px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .tns-carousel {
      .tns-ovh {
        overflow: visible;
      }
    }
  }
}

#product-reviews {
  h2 {
    margin-bottom: 13px;
  }
  .overall-wrp {
    margin-bottom: 12px;

    .total-reviews {
      margin-bottom: 40px;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    .summary-wrp {
      display: grid;
      column-gap: 30px;
      height: 480px;

      .main-raiting-wrp {
        padding: 40px 20px;
        color: $white;
        background-image: url("/images/frontend/reviews_bg1.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .main-raiting {
          display: grid;
          grid-auto-flow: row;
          justify-content: center;
          text-align: center;

          > span {
            margin-bottom: 3px;
            font-style: italic;
            font-weight: 700;
            font-size: 64px;
            line-height: 72px;
            font-family: $font-family-heading;
          }
          .star-rating {
            font-size: 2px;
          }
        }
      }
      .side-photo-wrp {
        background-image: url("/images/frontend/reviews_bg2.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
  #product-reviews-listing {
    .product-review {
      margin-bottom: 16px;
      background-color: $white;

      .summary-wrp {
        padding: 32px 32px 19px 32px;
        border-bottom: 1px solid $primary;

        h6 {
          margin-bottom: 0px;
          font-size: 20px;
          line-height: 26px;
        }
        time {
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 0.1em;
        }
        .parameters-wrp {
          margin-top: 25px;

          dl {
            display: grid;
            grid-template-columns: 60% auto;
            margin-bottom: 0px;

            dt, dd {
              margin-bottom: 8px;
              font-weight: 500;
              font-size: 10px;
              line-height: 16px;
              letter-spacing: 0.1em;
              text-transform: uppercase;
            }
            dt {
              padding-right: 15px;
            }
          }
        }
      }
      .text-wrp {
        padding: 28px 32px;

        .star-rating {
          display: flex;
          align-items: center;

          i {
            margin-right: 0.35rem;
            font-size: 16px;
            color: $color-dark;
          }
          small {
            margin-left: 6px;
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.1em;
          }
        }
        .recomend-wrp {
          margin-top: 5px;
          margin-bottom: 0px;
          font-size: 10px;
          line-height: 16px;
        }
        p.review-text-wrp {
          margin-top: 17px;
          font-size: 14px;
          line-height: 24px;
        }
      }
      .photos-wrp {
        display: grid;
        padding: 16px 16px 16px 20px;
        margin-left: auto;
        margin-right: auto;
        max-width: 400px;


        &.photos-1 {
          grid-template-areas: "photo1";
        }
        &.photos-2 {
          grid-template-areas: "photo1"
                               "photo2";
        }
        &.photos-3, &.photos-more {
          grid-template-columns: minmax(64%, 64%) minmax(36%, 36%);
          grid-template-areas: "photo1 photo2"
                               "photo1 photo3"
                               "photo1 photo4";
        }
        .product-review-thumb {
          position: relative;

          &:nth-child(1) {
            grid-area: photo1;
          }
          &:nth-child(2) {
            grid-area: photo2;
          }
          &:nth-child(3) {
            grid-area: photo3;
          }
          &:nth-child(4) {
            grid-area: photo4;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          span {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            background-color: rgba(34, 34, 34, 0.6);
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    h2 {
      font-size: 36px;
      line-height: 47px;
    }
    .overall-wrp {
      margin-bottom: 32px;

      .summary-wrp {
        grid-auto-flow: column;
        grid-auto-columns: minmax(0, 1fr);
      }
    }
    #product-reviews-listing {
      .product-review {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 344px auto 260px;
        min-height: 348px;

        .summary-wrp {
          padding: 32px 40px;
          border-bottom: none;
          border-right: 2px solid $primary;

          h6 {
            font-size: 28px;
            line-height: 36px;
          }
          time {
            display: none;
          }
          .parameters-wrp {
            dl {
              grid-template-columns: 55% auto;
            }
          }
        }
        .text-wrp {
          display: flex;
          flex-direction: column;
          padding: 31px 35px 31px 56px;

          .star-rating {
            i {
              margin-right: 0.25rem;
              font-size: 11px;
            }
            small {
              margin-left: 8px;
            }
          }
          p.review-text-wrp {
            flex-grow: 1;
            margin-bottom: 27px;
          }
          time {
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.1em;
          }
        }
        .photos-wrp {
        }
      }
    }
  }
}