//
// Blog module styles
// --------------------------------------------------

.blog-subtitle {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: $font-family-basier-squere;
}
#blog-categories {
  margin-bottom: 22px;
  padding: 16px 0px;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.1em;

  .tns-ovh {
    overflow: visible;
  }
  .subcategory-element {
    text-transform: uppercase;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 31px;
    padding-top: 2px;

    .slider-wrp {
      text-align: center;
    }
  }
}
.news-item {
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: 56px;

  .inner-wrp {
    display: grid;
    grid-template-columns: minmax(0, 58px) minmax(0, 1fr);
    grid-template-areas: "category photo"
                           "empty content";

    .blog-entry-meta-link {
      grid-area: category;
      align-self: center;
      justify-self: center;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      writing-mode: vertical-rl;
      transform: rotate(-180deg);
    }
    .blog-entry-thumb {
      grid-area: photo;
      display: flex;
      justify-content: center;

      .big-block-img {
        display: none;
      }
    }
    .card-body {
      grid-area: content;
      padding: 28px 20px 0px 0px;

      .blog-entry-meta-link {
        display: none;
      }

      h2 {
        margin-bottom: 21px;
      }
      .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
      .read-more {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-decoration-line: underline;
      }
    }
  }
  &:nth-child(4n+1) {
    margin-left: 0px;
    margin-right: 0px;

    .inner-wrp {
      display: block;

      > .blog-entry-meta-link {
        display: none;
      }
      .blog-entry-thumb {
        display: block;
        margin-left: -20px;
        margin-right: -20px;

        .small-block-img {
          display: none;
        }
        .big-block-img {
          display: block;
        }
      }
      .card-body {
        position: relative;
        margin-top: -81px;
        padding: 1.35rem 1.55rem;
        background-color: $white;

        .blog-entry-meta-link {
          display: block;
          transform: initial;
          writing-mode: initial;
        }
        h2 {
          margin-top: 13px;
          margin-bottom: 12px;
        }
        .description {
          margin-bottom: 20px;
        }
        .read-more {
          display: inline-block;
          padding-bottom: 14px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 64px;

    .inner-wrp {
      grid-template-columns: minmax(0, 1fr);
      grid-template-areas: "photo"
                             "content";

      > .blog-entry-meta-link {
        display: none;
      }
      .card-body {
        padding: 25px 0px 0px 0px;

        .blog-entry-meta-link {
          align-self: start;
          display: block;
          margin-bottom: 10px;
          writing-mode: initial;
          transform: initial;
        }
        h2 {
          margin-bottom: 17px;
          font-weight: 400;
          font-size: 28px;
          line-height: 37px;
        }
        .description {

        }
      }
    }
    &:nth-child(4n+1) {
      .inner-wrp {
        display: grid;
        grid-template-columns: minmax(0, 34%) minmax(0, 66%);
        grid-template-areas: "content photo";

        .blog-entry-thumb {
          order: 2;
          margin-left: 0px;
          margin-right: 0px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .card-body {
          order: 1;
          display: flex;
          flex-direction: column;
          margin-top: 0px;
          padding: 56px 58px 56px 48px;

          h2 {
            margin-bottom: 24px;
          }
          .description {
            flex-grow: 1;
          }
          .read-more {
            padding-bottom: 0px;
          }
        }
      }
    }
  }
}
#news-listing {
  overflow: hidden;

  .news-listing {}

  @include media-breakpoint-up(lg) {
    .news-listing {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 30px;

      .news-item {
        &:nth-child(4n+1) {
          grid-column: 1/span 3;
        }
      }
    }
  }
}
#news_detailed {
  .page_editor_template {
    max-width: 1130px;

    &.full_width {
      max-width: 100%;
    }
  }
}
