#languagesMenu, #languagesMenuFooter {
  > button, > .topbar-link {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 24px;

    &:after {
      display: none;
    }
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 24px;
  }
  .flag-wrp {
    .flag {
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 12px;
      background-size: cover;
      background-position: center;
      background: #eaeaea;
    }
  }
}

#ProjectSelectPopupLink, #FooterProjectSelectPopupLink .content-wrp {
  > a {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 24px;
  }
  .flag-wrp {
    .flag {
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 12px;
      background-size: cover;
      background-position: center;
      background: #eaeaea;
    }
  }
}